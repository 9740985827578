import facade from 'context/UserContext/facade';
import { useObservableState } from 'observable-hooks';

const defaults = {
   context: null,
   userId: null,
   // locale: null,
   // language: null,
   // supportedLanguage: null,
   notifications: [],
   organisations: [],
   reportingStandards: [],
   reportingPeriods: [],
   includeSubsidiaries: false,
   pageSize: 25,
   groups: [],
   rules: [],
   layouts: [],
}

export default function useUserContext() {
   return {
      state: new Proxy(
         {},
         {
            /* eslint-disable react-hooks/rules-of-hooks */
            get: (target, prop) => {
               if (prop === 'error') {
                  return useObservableState(facade.query.selectError());
               }
               if (prop === 'isLoading') {
                  return useObservableState(facade.loading$, true);
               }
               if (prop === 'context') {
                  return useObservableState(facade.reducedContext$, defaults[prop]);
               }
               return facade[`${prop}$`] ? useObservableState(facade[`${prop}$`], defaults[prop]) : null;
            },
            /* eslint-enable react-hooks/rules-of-hooks */
         }
      ),
      api: facade.api,
   };
}
