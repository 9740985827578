import CRUDService from 'state/CRUDService';
import { query } from './query';
import store from './store';

export default class ReportDocumentsService extends CRUDService {
   constructor() {
      if (!ReportDocumentsService.instance) {
         super('reportdocuments', store, query, [], true, false, undefined, false);
         ReportDocumentsService.instance = this;
      }

      // Service shall be instantiated only once, because otherwise the observable will be created for each service instance

      return ReportDocumentsService.instance;
   }

   getActiveDocumentId() {
      return this.query.getActive()?.documentId;
   }

   async saveDocumentRevision(documentId, data) {
      return this.httpClient.post(`/${this.version}/${this.entityName}/${documentId}/revisions`, data);
   }

   async createEntity(entity) {
      return this.httpClient
         .post(`/${this.version}/${this.entityName}`, entity)
         .then((resp) => {
            this.store.add(resp.data);
            return resp.data;
         })
         .catch((error) => {
            this.setError(error);
         });
   }
}
