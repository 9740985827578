import { activeEntity$, entities$, loading$, query } from './query';
import ReportDocumentTemplatesService from './service';

const api = new ReportDocumentTemplatesService();

const facade = {
   api,
   query,
   entities$,
   activeEntity$,
   loading$,
};

export default facade;
