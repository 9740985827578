import { createEntityQuery } from '@datorama/akita';
import store from './store';

export const query = createEntityQuery(store);

export const entities$ = query.selectAll();
export const activeEntity$ = query.selectActive();
export const activeEntityId$ = query.selectActiveId();
export const loading$ = query.selectLoading();
export const activeDocumentId$ = query.selectActive((entity) => entity.documentId);
export const activeReportId$ = query.selectActive((entity) => entity.report.id);
