import facade from 'context/ApplicationContext/facade';
import { ApplicationContext } from 'context/index';
import { useObservableState } from 'observable-hooks';
import { useContext } from 'react';

const defaults = {
};

export default function useApplicationContext() {
   const underlyingContext = useContext(ApplicationContext);

   return {
      state: new Proxy(
         {},
         {
            /* eslint-disable react-hooks/rules-of-hooks */
            get: (target, prop) => {
               if (prop === 'error') {
                  return useObservableState(facade.query.selectError());
               }
               if (prop === 'isLoading') {
                  return useObservableState(facade.loading$, true);
               }
               if (prop === 'context') {
                  return useObservableState(underlyingContext);
               }

               return facade[`${prop}$`] ? useObservableState(facade[`${prop}$`], defaults[prop]) : null;
            },
            /* eslint-enable react-hooks/rules-of-hooks */
         }
      ),
      api: facade.api,
   };
}
