import { useObservableState } from 'observable-hooks';
import facade from './facade';

const defaults = {
   entities: [],
   activeEntity: null,
   report: [],
   reportData: [],
   valuesOrItems: [],
};

export default function useKPIValuesFacade() {
   return {
      state: new Proxy(
         {},
         {
            /* eslint-disable react-hooks/rules-of-hooks */
            get: (target, prop) => {
               if (prop === 'error') {
                  return useObservableState(facade.query.selectError());
               }
               if (prop === 'isLoading') {
                  return useObservableState(facade.loading$, true);
               }
               if (prop === 'valuesOrItems') {
                  return useObservableState(facade.entitiesWithFlattenedItems$, defaults[prop]);
               }

               return facade[`${prop}$`] ? useObservableState(facade[`${prop}$`], defaults[prop]) : null;
            },
            /* eslint-enable react-hooks/rules-of-hooks */
         }
      ),
      api: facade.api,
   };
}
