import { combineQueries, createEntityQuery, Order } from '@datorama/akita';
import { locale$ } from 'context/UserContext/query';
import { localeIncludes } from 'locale-includes';
import { map } from 'rxjs';
import { query as UIQuery } from 'state/UI/query';
import { nestOrganisations } from 'utils/index';
import store from './store';

export const query = createEntityQuery(store, {
   sortBy: 'id',
   sortByOrder: Order.ASC,
});

export const entities$ = query.selectAll().pipe(
   map((organisations) =>
      organisations.map((organisation) => ({
         ...organisation,
         label: organisation.code ? `(${organisation.code}) ${organisation.name}` : organisation.name,
         value: organisation.id,
      }))
   )
);

export const filteredEntities$ = combineQueries([entities$, UIQuery.select('searchOrganizationList'), locale$]).pipe(
   map(([entities, searchTerm, userLocale]) =>
      entities.filter(
         ({ name, code }) =>
            !searchTerm ||
            localeIncludes(name ?? '', searchTerm ?? '', {
               locales: userLocale ?? 'en',
               usage: 'search',
               sensitivity: 'base',
               ignorePunctuation: true,
            }) ||
            localeIncludes(code ?? '', searchTerm ?? '', {
               locales: userLocale ?? 'en',
               usage: 'search',
               sensitivity: 'base',
               ignorePunctuation: true,
            })
      )
   )
);

export const activeEntity$ = query.selectActive();

export const entityTree$ = entities$.pipe(
   map((organisations) => nestOrganisations(organisations ?? []).sort((a, b) => a.children.length - b.children.length))
);

export const statistics$ = query.select('statistics');
export const loading$ = query.selectLoading();

export const entitiesBeingDeleted$ = query.selectAll({
   filterBy: (entity) => !!entity?.deleteOperationStatus,
});

export const sort$ = query.select('sort');
