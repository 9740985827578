import { applyTransaction, arrayAdd, arrayRemove, arrayUpdate, arrayUpsert, combineQueries } from '@datorama/akita';
import { activeOrganisationId$, organisationContextQuery } from 'context/OrganisationContext/query';
import { context$ as periodContext$ } from 'context/PeriodContext/query';
import { includeSubsidiaries$ } from 'context/UserContext/query';
import { formatISO, isDate, parseISO } from 'date-fns';
import { has, isEmpty, isEqual, isNil, omit, pick } from 'lodash-es';
import { distinctUntilChanged, firstValueFrom, tap } from 'rxjs';
import approvalCenterStore from 'state/ApprovalCenter/store';
import CRUDService from 'state/CRUDService';
import { query as kpisQuery } from 'state/KPI/query';
import kpisStore from 'state/KPI/store';
import KPISubsService from 'state/KPISub/service';
import kpiSubsStore from 'state/KPISub/store';
import KPIValuesService from 'state/KPIValue/service';
import kpiValuesStore from 'state/KPIValue/store';
import { fieldTypeIsNumeric } from 'utils';
import { ApprovalStatus, KPIFieldLinkType } from 'utils/enum';
import { query } from './query';
import store from './store';

export default class KPIFieldsService extends CRUDService {
   constructor() {
      if (!KPIFieldsService.instance) {
         super(
            'kpifields',
            store,
            query,
            { comments: store.storeName, tasks: 'tasks', values: 'kpiValues', links: store.storeName },
            true,
            true,
            undefined,
            false,
            true
         );

         this.isResolving = false;
         this.kpiValuesStore = kpiValuesStore;
         this.approvalCenterStore = approvalCenterStore;
         this.kpiValuesService = new KPIValuesService();
         this.kpiSubsService = new KPISubsService();
         this.organisationContextQuery = organisationContextQuery;

         this.sumsController = new AbortController();
         this.pendingSumRequest = new Set();

         this.refreshProgressObservable = combineQueries([activeOrganisationId$, periodContext$, includeSubsidiaries$])
            .pipe(
               distinctUntilChanged(isEqual),
               tap(() => this.refreshCurrentKPIProgress(false))
            )
            .subscribe();

         KPIFieldsService.instance = this;
      }

      // KPIFieldsService shall be instantiated only once, because otherwise the observable will be created for each service instance

      return KPIFieldsService.instance;
   }

   setActiveMeta(activeMeta) {
      this.store.update({ activeMeta });
   }

   setActiveFieldGroup(activeFieldGroup) {
      this.store.update({ activeFieldGroup });
   }

   setActiveRow(activeRow) {
      this.store.update({ activeRow });
   }

   async createMeta(kpiFieldId, meta, updateModel = true) {
      const collectionName = 'metas';

      this.httpClient
         .post(`/${this.version}/kpifielditemmetas`, { kpiFieldId, ...meta })
         .then((resp) => {
            if (updateModel) {
               this.store.update(kpiFieldId, ({ [collectionName]: collectionName_ }) => ({
                  [collectionName]: arrayAdd(collectionName_, resp.data),
               }));
            }

            return resp.data;
         })
         .catch((error) => {
            this.setError(error);
         });
   }

   async updateMeta(kpiFieldId, kpiFieldMetaId, changes, updateModel = true) {
      const collectionName = 'metas';

      this.httpClient
         .patch(`/${this.version}/kpifielditemmetas/${kpiFieldMetaId}`, changes)
         .then((resp) => {
            if (updateModel) {
               this.store.update(kpiFieldId, ({ [collectionName]: collectionName_ }) => ({
                  [collectionName]: arrayUpsert(collectionName_, kpiFieldMetaId, changes),
               }));
            }

            return resp.data;
         })
         .catch((error) => {
            this.setError(error);
         });
   }

   async deleteMeta(kpiFieldId, kpiFieldMetaId) {
      const collectionName = 'metas';

      return this.httpClient
         .delete(`/${this.version}/kpifielditemmetas/${kpiFieldMetaId}`)
         .then(() =>
            this.store.update(kpiFieldId, ({ [collectionName]: collectionName_ }) => ({
               [collectionName]: arrayRemove(collectionName_, kpiFieldMetaId),
            }))
         )
         .catch((error) => {
            this.setError(error);
         });
   }

   async createColumn(kpiFieldId, columnData) {
      const collectionName = 'columns';

      return this.httpClient
         .post(`/${this.version}/${this.entityName}/${kpiFieldId}/${collectionName}`, columnData)
         .then((resp) => this.store.add(resp.data))
         .catch((error) => {
            this.setError(error);
         });
   }

   async updateColumn(kpiFieldId, columnFieldId, changes, updateModel = true) {
      const collectionName = 'columns';

      return this.httpClient
         .patch(`/${this.version}/${this.entityName}/${kpiFieldId}/${collectionName}/${columnFieldId}`, changes)
         .then((resp) => {
            if (updateModel) {
               this.store.update(resp.data.id, resp.data);
            }

            return resp.data;
         })
         .catch((error) => {
            this.setError(error);
         });
   }

   async deleteColumn(kpiFieldId, columnFieldId) {
      const collectionName = 'columns';

      return this.httpClient
         .delete(`/${this.version}/${this.entityName}/${kpiFieldId}/${collectionName}/${columnFieldId}`)
         .then(() => this.store.remove(columnFieldId))
         .catch((error) => {
            this.setError(error);
         });
   }

   async createRow(kpiFieldId, rowData, updateModel = true) {
      const collectionName = 'rows';

      return this.httpClient
         .post(`/${this.version}/${this.entityName}/${kpiFieldId}/${collectionName}`, rowData)
         .then((resp) => {
            if (updateModel) {
               this.store.update(kpiFieldId, ({ [collectionName]: collectionName_ }) => ({
                  [collectionName]: arrayAdd(collectionName_, resp.data),
               }));
            }

            return resp.data;
         })
         .catch((error) => {
            this.setError(error);
         });
   }

   async updateRow(kpiFieldId, rowId, changes, updateModel = true) {
      const collectionName = 'rows';

      return this.httpClient
         .patch(`/${this.version}/${this.entityName}/${kpiFieldId}/${collectionName}/${rowId}`, changes)
         .then((resp) => {
            if (updateModel) {
               this.store.update(kpiFieldId, ({ [collectionName]: collectionName_ }) => ({
                  [collectionName]: arrayUpdate(collectionName_, rowId, resp.data),
               }));
            }

            return resp.data;
         })
         .catch((error) => {
            this.setError(error);
         });
   }

   async deleteRow(kpiFieldId, rowId) {
      const collectionName = 'rows';

      return this.httpClient
         .delete(`/${this.version}/${this.entityName}/${kpiFieldId}/${collectionName}/${rowId}`)
         .then((resp) => {
            this.store.update(kpiFieldId, ({ [collectionName]: collectionName_ }) => ({
               [collectionName]: arrayRemove(collectionName_, rowId),
            }));

            return resp.data;
         })
         .catch((error) => {
            this.setError(error);
         });
   }

   async sortRows(kpiFieldId, rowIds) {
      const collectionName = 'rows';

      return this.httpClient.post(`/${this.version}/${this.entityName}/${kpiFieldId}/${collectionName}/sort`, rowIds).catch((error) => {
         this.setError(error);
      });
   }

   async sortColumns(kpiFieldId, columnIds) {
      const collectionName = 'columns';

      return this.httpClient.post(`/${this.version}/${this.entityName}/${kpiFieldId}/${collectionName}/sort`, columnIds).catch((error) => {
         this.setError(error);
      });
   }

   sanitizeValues(kpiValues, targetElement) {
      return kpiValues.map((value) => {
         const updatedValue = omit(value, ['field', 'hasValues', 'hasValueItems', 'kpiValuesCount']);
         if (targetElement === 'kpiValue' && has(targetElement, 'kpiValueId')) {
            delete updatedValue.kpiValueId;
         }

         ['from', 'to', 'date'].forEach((datePropName) => {
            if (updatedValue?.[datePropName] && isDate(updatedValue[datePropName])) {
               updatedValue[datePropName] = formatISO(updatedValue[datePropName], { representation: 'date' });
            }
         });

         return updatedValue;
      });
   }

   async persistValues(values, targetElement = 'kpiValue') {
      let corePath = '/kpivalues';
      if (targetElement === 'kpiValueItem') {
         corePath = '/kpivalueitems';
      }

      const valuesToCreate = this.sanitizeValues(
         values.filter(
            (val) =>
               (!isNil(val.value) || (isNil(val.value) && targetElement === 'kpiValueItem')) &&
               val.value !== '' &&
               (val?.id < 0 || !val?.id) &&
               val._action !== 'delete'
         ),
         targetElement
      ).map((val) => omit(val, ['id']));

      const valuesToUpdate = this.sanitizeValues(
         values.filter(
            (val) =>
               (!isNil(val.value) || (isNil(val.value) && targetElement === 'kpiValueItem')) &&
               val.value !== '' &&
               val?.id > 0 &&
               val._action !== 'delete'
         ),
         targetElement
      );

      const valuesToDelete = values.filter(
         ({ id, value, kpiValuesCount, _action }) =>
            _action == 'delete' || ((kpiValuesCount ?? 1) <= 1 && id > 0 && ((isNil(value) && targetElement !== 'kpiValueItem') || value === ''))
      );

      const promises = [];
      const deletePromises = [];

      if (valuesToCreate.length > 0) {
         promises.push(
            this.httpClient
               .post(`/${this.version}${corePath}`, valuesToCreate)
               .then((resp) => resp.data)
               .catch((error) => this.setError(error))
         );
      }

      if (valuesToUpdate.length > 0) {
         promises.push(
            this.httpClient
               .patch(`/${this.version}${corePath}`, valuesToUpdate)
               .then((resp) => resp.data)
               .catch((error) => this.setError(error))
         );
      }

      if (valuesToDelete.length > 0) {
         let data = valuesToDelete;
         if (targetElement === 'kpiValue') {
            data = valuesToDelete.map(({ kpiValueId, id }) => kpiValueId ?? id);
         }
         deletePromises.push(this.httpClient.delete(`/${this.version}${corePath}`, { data }).catch((error) => this.setError(error)));
      }

      await Promise.all(deletePromises);

      const promiseResults = await Promise.all(promises);

      let newValues = promiseResults.flat();

      if (targetElement === 'kpiValueItem' && newValues.length > 0) {
         newValues = newValues[0]?.items;
      }

      if (targetElement === 'kpiValue' && newValues.some((value) => (value?.items ?? []).length > 0)) {
         newValues = newValues.flatMap((value) => {
            if (Array.isArray(value?.items) && value?.items.length > 0) {
               return value?.items;
            } else {
               return value;
            }
         });
      }

      return [
         ...values.filter(
            (value) =>
               !!value.id &&
               !isNil(value?.value) &&
               value?.value !== '' &&
               value?._action !== 'delete' &&
               !(newValues ?? []).some((newValue) => newValue?.rowId === value?.rowId && newValue?.kpiFieldId === value?.kpiFieldId) &&
               !(newValues ?? []).some((newValue) => newValue?.kpiValueId === value?.kpiValueId && value?.objectType === 'kpiValue')
         ),
         ...(newValues ?? []).flat(),
      ];
   }

   async persistFormulas(formulas) {
      const formulasToCreate = formulas
         .filter(
            (formula) => !isNil(formula.formula) && !isEmpty(formula.formula) && (formula?.id < 0 || !formula?.id) && formula._action !== 'delete'
         )
         .map((formulaObj) => ({
            ...formulaObj,
            formula: formulaObj.formula.map((formula) => {
               if (typeof formula === 'object') {
                  return {
                     ...pick(formula, [
                        'id',
                        'slug',
                        'name',
                        'row',
                        'kind',
                        'type',
                        'relativePeriods',
                        'scope',
                        'category',
                        'method',
                        'sourceType',
                        'value',
                     ]),
                  };
               } else {
                  return formula;
               }
            }),
         }));

      const formulasToUpdate = formulas
         .filter(
            (formula) => !isNil(formula.formula) && !isEmpty(formula.formula) && formula?.id > 0 && formula._action !== 'delete' && formula?.updated
         )
         .map((formulaObj) => ({
            ...formulaObj,
            formula: formulaObj.formula.map((formula) => {
               if (typeof formula === 'object') {
                  return {
                     ...pick(formula, [
                        'id',
                        'slug',
                        'name',
                        'row',
                        'kind',
                        'type',
                        'relativePeriods',
                        'scope',
                        'category',
                        'method',
                        'sourceType',
                        'value',
                     ]),
                  };
               } else {
                  return formula;
               }
            }),
         }));

      const formulasToDelete = formulas.filter(
         ({ id, formula, kpiValuesCount, _action }) =>
            _action == 'delete' || ((kpiValuesCount ?? 1) <= 1 && id > 0 && (isNil(formula) || isEmpty(formula)))
      );

      const promises = [];
      const deletePromises = [];

      if (formulasToCreate.length > 0) {
         promises.push(
            this.httpClient
               .post(`/${this.version}/formulas`, formulasToCreate)
               .then((resp) => resp.data)
               .catch((error) => this.setError(error))
         );
      }

      if (formulasToUpdate.length > 0) {
         promises.push(
            this.httpClient
               .patch(`/${this.version}/formulas`, formulasToUpdate)
               .then((resp) => resp.data)
               .catch((error) => this.setError(error))
         );
      }

      if (formulasToDelete.length > 0) {
         const data = formulasToDelete.map(({ id }) => id);

         deletePromises.push(this.httpClient.delete(`/${this.version}/formulas`, { data }).catch((error) => this.setError(error)));
      }

      await Promise.all(deletePromises);

      const promiseResults = await Promise.all(promises);

      const newFormulas = promiseResults.flat();

      return [
         ...formulas.filter(
            ({ rowId, kpiFieldId, _action, value }) =>
               !isNil(value) &&
               !isEmpty(value) &&
               _action !== 'delete' &&
               !newFormulas.some((newValue) => newValue.rowId === rowId && newValue.kpiFieldId === kpiFieldId)
         ),
         ...newFormulas,
      ];
   }

   async createItems(kpiFieldId, items) {
      const collectionName = 'items';

      this.store.setLoading(true);

      return this.httpClient
         .post(`/${this.version}/${this.entityName}/${kpiFieldId}/items`, items)
         .then((resp) =>
            applyTransaction(() => {
               resp.data.forEach((item) =>
                  this.store.update(kpiFieldId, ({ [collectionName]: collectionName_ }) => ({
                     [collectionName]: arrayUpsert(collectionName_, item.id, item),
                  }))
               );

               items.forEach((item) =>
                  this.store.update(kpiFieldId, ({ [collectionName]: collectionName_ }) => ({
                     [collectionName]: arrayRemove(collectionName_, item.id),
                  }))
               );

               return this.store.setLoading(false);
            })
         )
         .catch((error) => {
            this.setError(error);
         });
   }

   async deleteItems(kpiFieldId, items) {
      const collectionName = 'items';
      const promises = [];

      this.store.setLoading(true);

      applyTransaction(() => {
         items
            .filter(({ id }) => !!id)
            .forEach((item) =>
               promises.push(
                  this.httpClient
                     .delete(`/${this.version}/${this.entityName}/${kpiFieldId}/${collectionName}/${item.id}`)
                     .then(() => this.deleteCollectionEntityFromState(kpiFieldId, collectionName, item.id))
                     .catch((error) => {
                        this.setError(error);
                     })
               )
            );
      });

      await Promise.all(promises);
      return this.store.setLoading(false);
   }

   async updateItems(kpiFieldId, items) {
      const collectionName = 'items';

      this.store.setLoading(true);

      return this.httpClient
         .patch(`/${this.version}/${this.entityName}/${kpiFieldId}/${collectionName}`, items)
         .then((resp) => {
            applyTransaction(() => {
               resp.data.forEach((item) =>
                  this.store.update(kpiFieldId, ({ [collectionName]: collectionName_ }) => ({
                     [collectionName]: arrayUpdate(collectionName_, item.id, item),
                  }))
               );
            });
            return this.store.setLoading(false);
         })
         .catch((error) => {
            this.setError(error);
         });
   }

   #setStatus(status, kpiFieldId, organisationId, from, to) {
      const user = pick(this.userContextQuery.getValue(), [
         'id',
         'salutation',
         'image',
         'userName',
         'firstName',
         'lastName',
         'email',
         'lang',
         'locale',
      ]);

      const setStatusValues = { status };

      switch (status) {
         case ApprovalStatus.IN_PROGRESS:
            setStatusValues.reviewer = null;
            setStatusValues.approver = null;
            break;
         case ApprovalStatus.REVIEWED:
            setStatusValues.reviewer = user;
            setStatusValues.approver = null;
            break;
         case ApprovalStatus.APPROVED:
            setStatusValues.approver = user;
            break;
         case 'UNARCHIVE':
            setStatusValues.status = ApprovalStatus.APPROVED;
            break;
         default:
            break;
      }

      this.store.update(kpiFieldId, { status, ...setStatusValues });
      this.kpiValuesStore.update(
         (entity) =>
            entity.kpiFieldId === kpiFieldId &&
            entity.organisationId === organisationId &&
            parseISO(entity.from) >= from &&
            (parseISO(entity.to) <= to || !entity.to),
         { ...setStatusValues }
      );
   }

   async createComment(entityId, payload) {
      // payload: { comment, organisationId }
      const collectionName = 'comments';

      return this.httpClient
         .post(`/${this.version}/${this.entityName}/${entityId}/comments`, payload)
         .then((resp) =>
            this.store.update(entityId, ({ [collectionName]: collectionName_ }) => ({
               [collectionName]: arrayAdd(collectionName_, resp.data),
               hasComments: true,
            }))
         )
         .catch((error) => {
            this.setError(error);
         });
   }

   async updateComment(entityId, payload) {
      const collectionName = 'comments';
      return this.updateEntityCollection(entityId, collectionName, payload.id, payload);
   }

   async deleteValues(kpiFieldId, organisationId, from, to, updateStore = true, rowId = null) {
      kpiValuesStore.setLoading(true);

      const params = new URLSearchParams();

      if (organisationId) {
         params.set('organisationId', organisationId);
      }
      if (from) {
         params.set('from', formatISO(from, { representation: 'date' }));
      }
      if (to) {
         params.set('to', formatISO(to, { representation: 'date' }));
      }

      if (rowId) {
         params.set('rowId', rowId);
      }

      return this.httpClient
         .delete(`/${this.version}/${this.entityName}/${kpiFieldId}/values?${params.toString()}`)
         .then(() =>
            applyTransaction(async () => {
               if (updateStore) {
                  this.#setStatus(ApprovalStatus.IN_PROGRESS, kpiFieldId, organisationId, from, to);
                  this.store.update(kpiFieldId, ({ values }) => ({
                     kpiValuesSum: undefined,
                     kpiValuesCount: 0,
                     value: undefined,
                     kpiValueId: undefined,
                     values: arrayRemove(
                        values,
                        (val) =>
                           val.organisationId === organisationId &&
                           val.kpiFieldId === kpiFieldId &&
                           parseISO(val.from) >= from &&
                           parseISO(val.to) <= to
                     ),
                  }));

                  this.kpiValuesStore.remove(
                     (entity) =>
                        entity.kpiFieldId === kpiFieldId &&
                        entity.organisationId === organisationId &&
                        parseISO(entity.from) >= from &&
                        (parseISO(entity.to) <= to || !entity.to)
                  );

                  const kpiField = this.query.getEntity(kpiFieldId);

                  if (kpiField && fieldTypeIsNumeric(kpiField.type)) {
                     await this.kpiSubsService.getFieldSums(kpiField?.kpiContent?.kpiSub?.id, true);
                  }

                  this.refreshCurrentKPIProgress();
               }

               kpiValuesStore.setLoading(false);
            })
         )
         .catch((error) => {
            this.setError(error);
            kpiValuesStore.setLoading(false);
         });
   }

   async deleteCellFormulas(kpiFieldId, organisationId, from, to, updateStore = true, rowId = null) {
      kpiValuesStore.setLoading(true);

      const params = new URLSearchParams();

      if (organisationId) {
         params.set('organisationId', organisationId);
      }
      if (from) {
         params.set('from', formatISO(from, { representation: 'date' }));
      }
      if (to) {
         params.set('to', formatISO(to, { representation: 'date' }));
      }

      if (rowId) {
         params.set('rowId', rowId);
      }

      return this.httpClient
         .delete(`/${this.version}/${this.entityName}/${kpiFieldId}/formulas?${params.toString()}`)
         .then(() =>
            applyTransaction(async () => {
               // TODO remove formulas
               kpiValuesStore.setLoading(false);
            })
         )
         .catch((error) => {
            this.setError(error);
            kpiValuesStore.setLoading(false);
         });
   }

   async deleteComment(kpiFieldId, kpiFieldCommentId) {
      return this.deleteEntityFromCollection(kpiFieldId, 'comments', kpiFieldCommentId);
   }

   async deleteAttachment(kpiFieldId, kpiAttachmentId) {
      return this.httpClient
         .delete(`/${this.version}/${this.entityName}/${kpiFieldId}/attachments/${kpiAttachmentId}`)
         .then(() => this.store.update(kpiFieldId, ({ attachments }) => ({ attachments: arrayRemove(attachments, kpiAttachmentId) })))
         .catch((error) => {
            this.setError(error);
         });
   }

   async deleteRowAttachment(kpiFieldId, rowId, kpiAttachmentId) {
      const collectionName = 'rows';

      return this.httpClient
         .delete(`/${this.version}/${this.entityName}/${kpiFieldId}/rows/${rowId}/attachments/${kpiAttachmentId}`)
         .then(() =>
            this.store.update(kpiFieldId, ({ [collectionName]: collectionName_ }) => ({
               [collectionName]: collectionName_.map((row) => ({
                  ...row,
                  attachments: row?.id === rowId ? arrayRemove(row.attachments, kpiAttachmentId) : row?.attachments,
               })),
            }))
         )
         .catch((error) => {
            this.setError(error);
         });
   }

   async uploadFiles(entityId, files, organisationId, from, to) {
      applyTransaction(() => {
         files.forEach((file) => {
            const formData = new FormData();
            formData.append('attachment', file);
            formData.append('organisationId', organisationId);
            formData.append('from', from);
            formData.append('to', to);

            this.httpClient
               .post(`/${this.version}/${this.entityName}/${entityId}/attachments`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
               .then((resp) => {
                  return this.store.update(entityId, ({ attachments }) => ({ attachments: arrayAdd(attachments, resp.data) }));
               })
               .catch((error) => {
                  this.setError(error);
               });
         });
      });
   }

   async uploadFilesForRow(fieldId, rowId, files, organisationId, from, to) {
      const collectionName = 'rows';

      applyTransaction(() => {
         Object.entries(files).forEach(([, file]) => {
            const formData = new FormData();
            formData.append('attachment', file);
            formData.append('organisationId', organisationId);
            formData.append('from', from);
            formData.append('to', to);

            this.httpClient
               .post(`/${this.version}/${this.entityName}/${fieldId}/rows/${rowId}/attachments`, formData, {
                  headers: { 'Content-Type': 'multipart/form-data' },
               })
               .then((resp) =>
                  this.store.update(fieldId, ({ [collectionName]: collectionName_ }) => ({
                     [collectionName]: collectionName_.map((row) => ({
                        ...row,
                        attachments: row?.id === rowId ? arrayAdd(row.attachments, resp.data) : row?.attachments,
                     })),
                  }))
               )
               .catch((error) => {
                  this.setError(error);
               });
         });
      });
   }

   async createFieldLink(sourceKpiFieldId, targetKpiFieldId, validFrom, validTo, sourceRowId = null, targetRowId = null) {
      const collectionName = 'links';

      return this.httpClient
         .post(`/${this.version}/kpifieldlinks`, {
            sourceKpiFieldId,
            targetKpiFieldId,
            validFrom,
            validTo,
            type: KPIFieldLinkType.LINK,
            sourceRowId,
            targetRowId,
         })
         .then((resp) => {
            this.store.update(targetKpiFieldId, ({ [collectionName]: collectionName_ }) => ({
               [collectionName]: arrayAdd(collectionName_, resp.data),
            }));

            const kpiField = this.query.getEntity(sourceKpiFieldId);

            return this.kpiSubsService.getFieldSums(kpiField?.kpiContent?.kpiSub?.id, true);
         })
         .catch((error) => {
            this.setError(error);
         });
   }

   async deleteFieldLink(kpiFieldId, kpiFieldLinkId) {
      const collectionName = 'links';

      return this.httpClient
         .delete(`/${this.version}/kpifieldlinks/${kpiFieldLinkId}`)
         .then(() => {
            this.store.update(kpiFieldId, ({ [collectionName]: collectionName_ }) => ({
               [collectionName]: arrayRemove(collectionName_, kpiFieldLinkId),
            }));

            const kpiField = this.query.getEntity(kpiFieldId);

            return this.kpiSubsService.getFieldSums(kpiField?.kpiContent?.kpiSub?.id, true);
         })
         .catch((error) => {
            this.setError(error);
         });
   }

   async refreshCurrentKPIProgress(includeSubs = true) {
      const activeKPIId = kpisQuery.getActiveId();

      if (!this.queryParams) {
         await firstValueFrom(this.queryParamsObservable);
      }
      const params = new URLSearchParams(this.queryParams);

      const promises = [];

      if (activeKPIId) {
         promises.push(
            this.httpClient
               .get(`/${this.version}/kpis/${activeKPIId}/progress?${params.toString()}`)
               .then((resp) =>
                  kpisStore.update(
                     activeKPIId,
                     pick(resp.data, ['approved', 'filled', 'numAssignedTasks', 'numComments', 'numTasks', 'earliestDueDate'])
                  )
               )
               .catch((error) => {
                  this.setError(error);
               })
         );

         if (includeSubs) {
            promises.push(
               this.httpClient
                  .get(`/${this.version}/kpis/${activeKPIId}/subs?${params.toString()}`)
                  .then((resp) =>
                     kpiSubsStore.upsertMany(
                        resp.data.map((kpiSubInfo) =>
                           pick(kpiSubInfo, ['id', 'approved', 'filled', 'numAssignedTasks', 'numComments', 'numTasks', 'earliestDueDate'])
                        )
                     )
                  )
                  .catch((error) => {
                     this.setError(error);
                  })
            );
         }
      }

      if (!activeKPIId) {
         return Promise.resolve(false);
      }
      return Promise.all(promises);
   }

   async updateFormulaResults() {
      return this.httpClient
         .post(`/${this.version}/${this.entityName}/updateresults`)
         .then(() => undefined)
         .catch((error) => {
            this.setError(error);
         });
   }

   async updateKPIProgress() {
      return this.httpClient
         .post(`/${this.version}/${this.entityName}/updateprogress`)
         .then(() => undefined)
         .catch((error) => {
            this.setError(error);
         });
   }

   async saveValidationRules(entityId, rowId, payload) {
      const collectionName = 'validationRules';
      const urlParams = new URLSearchParams();
      if (rowId) {
         urlParams.set('rowId', rowId);
      }

      return this.httpClient
         .put(`/${this.version}/${this.entityName}/${entityId}/validationrules?${urlParams}`, payload)
         .then((resp) => {
            if (rowId) {
               const collectionNameRow = 'rows';

               return this.store.update(entityId, ({ [collectionNameRow]: collectionNameRow_ }) => ({
                  [collectionNameRow]: collectionNameRow_.map((row) => ({
                     ...row,
                     validationRules: row?.id === rowId ? resp.data : row?.validationRules,
                  })),
               }));
            } else {
               return this.store.update(entityId, () => ({
                  [collectionName]: resp.data,
               }));
            }
         })
         .catch((error) => {
            this.setError(error);
         });
   }

   async resolveFormulaArray(payload) {
      if (!this.queryParams) {
         await firstValueFrom(this.queryParamsObservable);
      }
      const params = new URLSearchParams(this.queryParams);

      return this.httpClient
         .post(`/${this.version}/formulas/resolve?${params.toString()}`, payload)
         .then((resp) => resp.data)
         .catch((error) => {
            this.setError(error);
         });
   }

   async setStatusApprovalCenter(setStatusValues, auditValues) {
      return this.approvalCenterStore.update((entity) => (auditValues ?? []).includes(entity?.kpiField?.id), {
         ...setStatusValues,
      });
   }

   async updateSum(entityId) {
      if (this.pendingSumRequest.has(entityId)) {
         return;
      }

      if (this.pendingSumRequest.size > 0 && !this.pendingSumRequest.has(entityId)) {
         this.sumsController.abort();
         this.sumsController = new AbortController();
         this.pendingSumRequest.clear();
      }

      this.pendingSumRequest.add(entityId);

      const organisationId = this.organisationContextQuery.getValue()?.id;

      return this.httpClient
         .get(`/${this.version}/${this.entityName}/${entityId}/sum${this.queryParams ?? ''}`)
         .then((resp) => {
            this.kpiValuesStore.remove(({ kpiFieldId }) => kpiFieldId === entityId);

            this.kpiValuesStore.setLoading(true);

            applyTransaction(() => {
               this.pendingSumRequest.delete(entityId);
               this.store.update(entityId, {
                  id: resp.data?.kpiFieldId,
                  ...pick(resp.data, [
                     'errors',
                     'status',
                     'hasValueItems',
                     'hasVersions',
                     'isEstimated',
                     'approver',
                     'reviewer',
                     'kpiValuesCount',
                     'currency',
                     'kpiValuesSumPreviousPeriod',
                  ]),
               });

               const { kpiValuesCount, organisationId: valueOrganisationId } = resp.data;

               if (kpiValuesCount !== 1 || valueOrganisationId !== organisationId) {
                  this.store.update(entityId, {
                     id: resp.data?.kpiFieldId,
                     ...omit(resp.data, ['kpiFieldId', 'kpiValueId']),
                  });
               }
            });

            return this.kpiValuesStore.setLoading(false);
         })
         .catch((error) => {
            this.pendingSumRequest.delete(entityId);
            this.setError(error);
            this.kpiValuesStore.setLoading(false);
         });
   }

   async pinComment(entityId, commentId) {
      const collectionName = 'comments';
      return this.updateEntityCollection(entityId, collectionName, commentId, { from: null, to: null });
   }

   async unpinComment(entityId, commentId, from, to) {
      const collectionName = 'comments';
      return this.updateEntityCollection(entityId, collectionName, commentId, { from, to });
   }

   async setActiveEntity(entityUniqueIdentifier, loadCollections = true, refresh = false, loadCollectionParams = undefined) {
      if (Array.isArray(loadCollections) && loadCollections.includes('values')) {
         this.kpiValuesStore.remove(({ kpiFieldId }) => kpiFieldId === entityUniqueIdentifier);

         await super.setActiveEntity(entityUniqueIdentifier, loadCollections, refresh, loadCollectionParams);
      } else {
         return super.setActiveEntity(entityUniqueIdentifier, loadCollections, refresh, loadCollectionParams);
      }
   }

   async transformYTD(entityId, fieldType, values, targetElement, type = 'TRANSFORM') {
      const urlParams = new URLSearchParams();
      const context = this.userContextQuery.getValue();
      urlParams.set('from', formatISO(parseISO(context.activePeriod.from), { representation: 'date' }));
      urlParams.set('to', formatISO(parseISO(context.activePeriod.to), { representation: 'date' }));
      urlParams.set('fieldId', entityId);
      urlParams.set('fieldType', fieldType);
      urlParams.set('type', type);

      let corePath = '/kpivalueitems';
      if (targetElement === 'kpiValue') {
         corePath = '/kpivalues/items';
      }

      return this.httpClient
         .post(`/${this.version}${corePath}?${urlParams}`, values)
         .then((resp) => {
            this.kpiValuesStore.remove(({ kpiFieldId, fpCalculationFieldId }) => kpiFieldId === entityId || fpCalculationFieldId === entityId);
            this.kpiValuesStore.upsertMany(resp.data);

            if (targetElement === 'kpiValueItem' && resp?.data.length === 1) {
               return resp?.data[0]?.items;
            }
            return resp.data;
         })
         .catch((error) => {
            this.setError(error);
         });
   }

   async deleteFormula(columnId, rowId, formulaId) {
      return this.httpClient.delete(`/${this.version}/${this.entityName}/${columnId}/rows/${rowId}/formulas/${formulaId}`).catch((error) => {
         this.setError(error);
      });
   }

   async createFormula(columnId, rowId, formula) {
      return this.httpClient.post(`/${this.version}/${this.entityName}/${columnId}/rows/${rowId}/formulas`, formula).catch((error) => {
         this.setError(error);
      });
   }

   async updateFormula(columnId, rowId, formulaId, changes) {
      return this.httpClient.post(`/${this.version}/${this.entityName}/${columnId}/rows/${rowId}/formulas/${formulaId}`, changes).catch((error) => {
         this.setError(error);
      });
   }

   unsubscribeProgress() {
      if (this.refreshProgressObservable && !this.refreshProgressObservable.closed) {
         this.refreshProgressObservable.unsubscribe();
      }
   }
}
