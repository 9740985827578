import { createStore } from '@datorama/akita';
import packageInfo from '../../../package.json';

export default createStore(
   {
      loading: true,
      version:
         process.env.NODE_ENV !== 'production'
            ? `${process.env.npm_package_version || packageInfo?.version} next`
            : process.env.npm_package_version || packageInfo?.version,
   },
   { name: 'applicationContext' }
);
