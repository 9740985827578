import { createEntityQuery } from '@datorama/akita';
import { compareAsc, parseISO } from 'date-fns';
import { map } from 'rxjs/operators';
import { groupBy } from 'utils';
import store from './store';

export const query = createEntityQuery(store, {
   sortBy: (a, b) => compareAsc(parseISO(a.from), parseISO(b.from)),
});

export const entities$ = query.selectAll();
export const activeEntity$ = query.selectActive();

export const entitiesByYear$ = entities$.pipe(map((data) => groupBy(data, (period) => parseISO(period.to).getFullYear())));
export const periodsFrom$ = entities$.pipe(map((data) => Array.from(new Set(data.map((period) => period.from)))));
export const periodsTo$ = entities$.pipe(map((data) => Array.from(new Set(data.map((period) => period.to)))));
export const loading$ = query.selectLoading();
