import { combineQueries, createEntityQuery } from '@datorama/akita';
import { isEqual } from 'lodash-es';
import { distinctUntilChanged, map } from 'rxjs';
import { entities$ as reportDocuments$ } from 'state/ReportDocument/query';
import store from './store';

export const query = createEntityQuery(store);

export const activeEntity$ = combineQueries([query.selectActive(), reportDocuments$]).pipe(
   map(([activeReport, reportDocuments]) =>
      activeReport
         ? {
              ...activeReport,
              documents: reportDocuments
                 ? reportDocuments.filter(
                      (reportDocument) => reportDocument.reportId === activeReport.id || reportDocument?.report?.id === activeReport.id
                   )
                 : [],
           }
         : undefined
   )
);

export const entities$ = combineQueries([query.selectAll(), reportDocuments$]).pipe(
   map(([reports, reportDocuments]) =>
      reports
         ? reports.map((report) => ({
              ...report,
              documents: reportDocuments
                 ? reportDocuments.filter((reportDocument) => reportDocument.reportId === report.id || reportDocument?.report?.id === report.id)
                 : [],
           }))
         : []
   ),
   distinctUntilChanged(isEqual)
);

export const statistics$ = query.select('statistics');

export const standardsInReports$ = query
   .selectAll()
   .pipe(map((reports) => (reports ? reports.flatMap((report) => (report.standards ?? []).map((standard) => standard.key) ?? []) : [])));
export const loading$ = query.selectLoading();
