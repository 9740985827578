import { Backdrop } from '@mui/material';
import EnvoriaLoadingIcon from 'components/v2/EnvoriaLoadingIcon';
import PropTypes from 'prop-types';

function FullPageLoadingIndicator({ open = true, sx = {}, children = null, ...restOfProps }) {
   return (
      <Backdrop open={open} sx={{ zIndex: (theme) => theme.zIndex.modal + 1, ...sx }} {...restOfProps}>
         <EnvoriaLoadingIcon />
         {children}
      </Backdrop>
   );
}

FullPageLoadingIndicator.propTypes = {
   open: PropTypes.bool,
   sx: PropTypes.shape({}),
   children: PropTypes.node,
};

export default FullPageLoadingIndicator;
