import type { AxiosInstance } from 'axios';
import axios from 'axios';

const axiosClient: AxiosInstance = axios.create({
   baseURL: `/api`,
   withCredentials: true,
});

axiosClient.defaults.headers.common['Accept-Language'] = 'en';

export default axiosClient;
