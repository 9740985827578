import { useObservableState } from 'observable-hooks';
import facade from './facade';

const defaults = {
};

export default function useDesignFacade() {
   return {
      state: new Proxy(
         {},
         {
            /* eslint-disable react-hooks/rules-of-hooks */
            get: (target, prop) => {
               if (prop === 'error') {
                  return useObservableState(facade.query.selectError());
               }
               if (prop === 'isLoading') {
                  return useObservableState(facade.loading$, true);
               }
               if (prop === 'design') {
                  return useObservableState(facade.value$);
               }

               return facade[`${prop}$`] ? useObservableState(facade[`${prop}$`], defaults[prop]) : null;
            },
            /* eslint-enable react-hooks/rules-of-hooks */
         }
      ),
   };
}
