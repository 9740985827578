import { combineQueries, createEntityQuery } from '@datorama/akita';
import { map } from 'rxjs';
import store from './store';

const theQuery = createEntityQuery(store);
theQuery.createUIQuery();

export const query = theQuery;
export const uiquery = theQuery.ui;

export const entities$ = combineQueries([query.selectAll(), uiquery.selectAll()]).pipe(
  map(([entitites]) =>
    entitites.map((entity) => ({
      ...entity,
      ui: uiquery.getEntity(entity.id),
    }))
  )
);

export const activeEntities$ = theQuery.selectActive();
export const activeEntityIds$ = theQuery.selectActiveId();
export const loading$ = query.selectLoading();
