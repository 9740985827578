export const FinancialStatementsLineItemType = {
   REVENUE: 'REV',
   CAPITAL_EXPENDITURE: 'CAPEX',
   OPERATIONAL_EXPENDITURE: 'OPEX',
   OTHER: 'OTHER',
} as const;

export const EntityRelationType = {
   CUSTOMER: 'CUSTOMER',
   SUPPLIER: 'SUPPLIER',
   SUBSIDIARY: 'SUBSIDIARY',
} as const;

export const KPIFieldType = {
   TEXTAREA: 'TEXTAREA',
   PRICE: 'PRICE',
   TEXT: 'TEXT',
   PERCENTAGE: 'PERCENTAGE',
   SELECT: 'SELECT',
   NUMBER: 'NUMBER',
   SURFACE: 'SURFACE',
   EMISSIONS: 'EMISSIONS',
   DATE: 'DATE',
   DISTANCE: 'DISTANCE',
   VOLUME: 'VOLUME',
   ELECTRICITY: 'ELECTRICITY',
   WEIGHT: 'WEIGHT',
   MEASUREMENT: 'MEASUREMENT',
   FORMULA: 'FORMULA',
   DATA: 'DATA',
   PASSENGER: 'PASSENGER',
   SWITCH: 'SWITCH',
   WOOD: 'WOOD',
   EMISSION_SELECT: 'EMISSION_SELECT',
   TABLE: 'TABLE',
   TIME: 'TIME',
} as const;

export const ApprovalStatus = {
   NO_VERIFICATION_NEEDED: 'NO_VERIFICATION_NEEDED',
   IN_PROGRESS: 'IN_PROGRESS',
   REVIEWED: 'REVIEWED',
   APPROVED: 'APPROVED',
   ARCHIVED: 'ARCHIVED',
} as const;

export const AggregationLevel = {
   AUTOMATIC: 'AUTOMATIC',
   YEAR: 'YEAR',
   HALF_YEAR: 'HALF_YEAR',
   QUARTER: 'QUARTER',
   MONTH: 'MONTH',
   YEAR_TO_DATE: 'YEAR_TO_DATE',
} as const;

export const TaxonomyActivityFilterType = {
   ALL: 'ALL',
   SUGGESTED: 'SUGGESTED',
} as const;

export const KPIValueType = {
   DECIMAL: 'DECIMAL',
   TEXT: 'TEXT',
   DATE: 'DATE',
   BOOLEAN: 'BOOLEAN',
   INTEGER: 'INTEGER',
} as const;

export const ChartType = {
   SCATTER_CHART: 'SCATTER_CHART',
   AREA_CHART: 'AREA_CHART',
   BAR_CHART: 'BAR_CHART',
   FUNNEL_CHART: 'FUNNEL_CHART',
   LINE_CHART: 'LINE_CHART',
   PIE_CHART: 'PIE_CHART',
   RADIAL_BAR_CHART: 'RADIAL_BAR_CHART',
   RADAR_CHART: 'RADAR_CHART',
   COMPOSED_CHART: 'COMPOSED_CHART',
   TABLE_UI: 'TABLE_UI',
   BAR: 'bar',
   BUBBLE: 'bubble',
   DOUGHNUT: 'dougnut',
   LINE: 'line',
   PIE: 'pie',
   POLAR_AREA: 'polarArea',
   RADAR: 'radar',
   SCATTER: 'scatter',
} as const;

export const CustomDiagrammType = {
   NUMBER: 'NUMBER',
   PERCENT: 'PERCENT',
   PERCENT_MANY: 'PERCENT_MANY',
   TABLE: 'TABLE',
} as const;

export const DiagrammType = {
   ...ChartType,
   ...CustomDiagrammType,
} as const;

export const OrganisationType = {
   REGION: 'REGION',
   COMMUNE: 'COMMUNE',
   ORGANIZATION: 'ORGANIZATION',
   AREA: 'AREA',
   PRODUCT: 'PRODUCT',
   PORTFOLIO: 'PORTFOLIO',
   INVESTMENT: 'INVESTMENT',
   SUBGROUP: 'SUBGROUP',
   BRANCH_OFFICE: 'BRANCH_OFFICE',
} as const;

export const TaskFormMode = {
   CREATE: 'CREATE',
   UPDATE: 'UPDATE',
} as const;

export const TaskActionType = {
   TASK: 'TASK',
   ACTION: 'ACTION',
   SUPPLIER_ACTION: 'SUPPLIER_ACTION',
} as const;

export const TaskStatus = {
   OPEN: 'OPEN',
   PROGRESS: 'PROGRESS',
   HOLD: 'HOLD',
   REVIEW: 'REVIEW',
   FINISHED: 'FINISHED',
   DELETED: 'DELETED',
   ARCHIVED: 'ARCHIVED',
} as const;

export const ReferencedArea = {
   GEN: 'GEN',
   KPI: 'KPI',
   ORG: 'ORG',
   SET: 'SET',
   REP: 'REP',
   EMI: 'EMI',
   EUT: 'EUT',
} as const;

export const ReferencePersonModel = {
   ASSIGNEE: 'Assignee',
   REVIEWER: 'Reviewer',
} as const;

export const TaxonomyAlignmentType = {
   ALIGNED: 'AL',
   ELIGIBLE: 'EL',
   NOT_ELIGIBLE: 'NE',
   NOT_ALIGNED: 'NA',
   PARTIALLY_ALIGNED: 'PA',
   OPEN: 'OP',
} as const;

export const TaxonomyContributionType = {
   NOT_QUALIFIED: 'NQ',
   MINIMUM_SAFEGUARDS: 'MS',
   SUBSTANTIAL_CONTRIBUTION: 'SC',
   DO_NO_SIGNIFICANT_HARM: 'DNSH',
   ENABLING_ACTIVITY: 'EA',
   TRANSITIONAL_ACTIVITY: 'TA',
} as const;

export const CommonActionType = {
   UPDATE: 'UPDATE',
   DELETE: 'DELETE',
   APPROVE: 'APPROVE',
   REVIEW: 'REVIEW',
   UNREVIEW: 'UNREVIEW',
   UNAPPROVE: 'UNAPPROVE',
   UNARCHIVE: 'UNARCHIVE',
} as const;

export const FootprintType = {
   ORGANISATION: 'ORGANISATION',
   PRODUCT: 'PRODUCT',
} as const;

export const SearchResultKind = {
   KPI_AREA: 'kpiarea',
   KPI: 'kpi',
   KPI_SUB: 'kpisub',
   KPI_CONTENT: 'kpicontent',
   KPI_FIELD: 'kpifield',
   BUSINESS_ACTIVITY: 'businessactivity',
   TAXONOMY_ACTIVITY: 'taxonomyactivity',
   EMISSION_CATEGORY: 'emissioncategory',
   EMISSION_FACTOR: 'emissionfactor',
   ORGANISATION: 'organisation',
   CHART: 'chart',
   REPORT: 'report',
   REPORT_DOCUMENT: 'reportdocument',
   REPORTING_STANDARD: 'reportingstandard',
   TASK: 'task',
   USER: 'user',
   GROUP: 'group',
   CURRENCY: 'currency',
   FOOTPRINT: 'footprint',
   FOOTPRINT_SUB: 'footprintsub',
   FOOTPRINT_CALCULATION: 'footprintcalculation',
   FP_CALCULATION: 'fpcalculation',
   FOOTPRINT_CALCULATION_FIELD: 'footprintcalculationfield',
   FP_CALCULATION_FIELD: 'fpcalculationfield',
   ATTACHMENT: 'attachment',
   TAXONOMYCRITERIARESPONSE: 'taxonomycriteriaresponse',
   SEARCH_HISTORY: 'searchHistory',
} as const;

export const TaskReferenceKind = {
   KPI_AREA: 'kpiarea',
   KPI: 'kpi',
   KPI_SUB: 'kpisub',
   KPI_FIELD: 'kpifield',
   BUSINESS_ACTIVITY: 'businessactivity',
   REPORT: 'report',
   FOOTPRINT: 'footprint',
   FOOTPRINT_CALCULATION_FIELD: 'footprintcalculationfield',
} as const;

export const CurrencyRateTypes = {
   CLOSING_RATE: 'CLOSING_RATE',
   AVERAGE_RATE: 'AVERAGE_RATE',
} as const;

export const ImportJobTypes = {
   SUCCESS: 'SUCCESS',
   ERROR: 'ERROR',
   WARNING: 'WARNING',
   PENDING: 'PENDING',
   DELETED: 'DELETED',
} as const;

export const GoalReferenceKind = {
   KPI_AREA: 'kpiArea',
   KPI: 'kpi',
   KPI_SUB: 'kpiSub',
   KPI_FIELD: 'kpiField',
   BUSINESS_ACTIVITY: 'businessactivity',
   REPORT: 'report',
   FOOTPRINT: 'footprint',
} as const;

export const TaskCycle = {
   ONCE: 'once',
   DAILY: 'days',
   WEEKLY: 'weeks',
   MONTHLY: 'months',
   QUARTERLY: 'quarters',
   YEARLY: 'years',
} as const;

export const KPIFieldLinkType = {
   LINK: 'LINK',
   SUGGESTION: 'SUGGESTION',
} as const;

export const LogKind = {
   EMAIL: 'EMAIL',
   CREATE: 'CREATE',
   UPDATE: 'UPDATE',
   DELETE: 'DELETE',
   USER_ACTION: 'USER_ACTION',
} as const;

export const ESRSMaterialityScaleType = {
   SCALE: 'SCALE',
   SCOPE: 'SCOPE',
   REMEDIABILITY: 'REMEDIABILITY',
   IMPACT_LIKELIHOOD: 'IMPACT_LIKELIHOOD',
   FINANCIAL_LIKELIHOOD: 'FINANCIAL_LIKELIHOOD',
   SIZE_OF_FINANCIAL_EFFECT: 'SIZE_OF_FINANCIAL_EFFECT',
} as const;

export const ESRSMaterialityImpactType = {
   ACTUAL: 'ACTUAL',
   POTENTIAL: 'POTENTIAL',
} as const;

export const ESRSMaterialityImpactValenceType = {
   POSITIVE: 'POSITIVE',
   NEGATIVE: 'NEGATIVE',
} as const;

export const ESRSMaterialityFinancialValenceType = {
   RISK: 'RISK',
   OPPORTUNITY: 'OPPORTUNITY',
} as const;

export const ESRSMaterialityType = {
   IMPACT: 'IMPACT',
   FINANCIAL: 'FINANCIAL',
} as const;

export const PeriodType = {
   INPUT: 'INPUT',
   AGGREGATION: 'AGGREGATION',
} as const;

export const ESRSFinancialEffectType = {
   FINANCIAL_POSITION: 'FINANCIAL_POSITION',
   FINANCIAL_PERFORMANCE: 'FINANCIAL_PERFORMANCE',
   CASH_FLOWS: 'CASH_FLOWS',
   ACCESS_TO_FINANCE: 'ACCESS_TO_FINANCE',
   COST_OF_CAPTIAL: 'COST_OF_CAPITAL',
} as const;

export const ESRSTimeHorizon = {
   SHORT_TERM: 'SHORT_TERM',
   MID_TERM: 'MID_TERM',
   LONG_TERM: 'LONG_TERM',
} as const;

export const AuditEntityFunction = {
   review: 'reviewEntity',
   unreview: 'unreviewEntity',
   reject: 'rejectEntity',
   approve: 'approveEntity',
   unapprove: 'unapproveEntity',
   archive: 'archiveEntity',
   unarchive: 'unarchiveEntity',
} as const;

export const NextAuditStatus = {
   review: 'REVIEWED',
   unreview: 'IN_PROGRESS',
   approve: 'APPROVED',
   reject: 'IN_PROGRESS',
   unapprove: 'IN_PROGRESS',
   archive: 'ARCHIVED',
   unarchive: 'APPROVED',
} as const;

export const EmissionWeightUnits = {
   kg: 'kg',
   t: 't',
   lb: 'lb',
   Mt: 'Mt',
   Gt: 'Gt',
} as const;

export const SupplierType = {
   DIRECT_SUPPLIER: 'DIRECT_SUPPLIER',
   INDIRECT_SUPPLIER: 'INDIRECT_SUPPLIER',
   SUB_SUPPLIER: 'SUB_SUPPLIER',
   OWN_ENTITY: 'OWN_ENTITY',
} as const;

export const SupplierSiteType = {
   MAIN: 'MAIN',
   PRODUCTION: 'PRODUCTION',
   ADMINISTRATION: 'ADMINISTRATION',
} as const;

export const SupplierValueChainPosition = {
   MATERIAL_INPUT: 'MATERIAL_INPUT',
   PRODUCTION: 'PRODUCTION',
   TRADER_RAW_PRODUCTION_MATERIAL: 'TRADER_RAW_PRODUCTION_MATERIAL',
   SEMI_FINISHED_PRODUCT: 'SEMI_FINISHED_PRODUCT',
   FINISHED_PRODUCT: 'FINISHED_PRODUCT',
   TRADER_FINISHED_PART: 'TRADER_FINISHED_PART',
} as const;

export const SupplierBusinessRelation = {
   NON_CRITICAL: 'NON_CRITICAL',
   BOTTLENECK: 'BOTTLENECK',
   STRATEGIC: 'STRATEGIC',
   LEVERAGE: 'LEVERAGE',
} as const;

export const OfficialEULanguagues = {
   BULGARIAN: 'BG',
   ESPANOL: 'ES',
   CZECH: 'CS',
   DANISH: 'DA',
   GERMAN: 'DE',
   ESTONIAN: 'ET',
   GREEK: 'EL',
   ENGLISH: 'EN',
   FRENCH: 'FR',
   IRISH: 'GA',
   CROATIAN: 'HR',
   ITALIAN: 'IT',
   LATVIAN: 'LV',
   LITHUANIAN: 'LT',
   HUNGARIAN: 'HU',
   MALTESE: 'MT',
   DUTCH_FLEMISH: 'NL',
   POLISH: 'PL',
   PORTUGUESE: 'PT',
   ROMANIAN_MOLDAVIAN_MOLDOVAN: 'RO',
   SLOVAK: 'SK',
   SLOVENIAN: 'SL',
   FINNISH: 'FI',
   SWEDISH: 'SV',
} as const;

export const ESRSValueChainPosition = {
   UPSTREAM: 'UPSTREAM',
   DOWNSTREAM: 'DOWNSTREAM',
   OWN_OPERATIONS: 'OWN_OPERATIONS',
} as const;

export const ESRSRiskType = {
   PHYSICAL: 'PHYSICAL',
   TRANSITION: 'TRANSITION',
} as const;

export const Tolerances = {
   NONE: 'NONE',
   PERCENTAGE: 'PERCENTAGE',
   ABSOLUTE: 'ABSOLUTE',
} as const;

export const AuditAction = {
   review: 'review',
   unreview: 'unreview',
   approve: 'approve',
   unapprove: 'unapprove',
   reject: 'reject',
   archive: 'archive',
   unarchive: 'unarchive',
} as const;

export const OrganisationsView = {
   OrganisationList: 'table',
   OrganisationHierarchyTree: 'tree',
   OrganisationChart: 'chart',
} as const;

export const CalculationFieldType = {
   FUEL_CONSUMPTION_COAL_COAL_PRODUCTS: 'FUEL_CONSUMPTION_COAL_COAL_PRODUCTS',
   FUEL_CONSUMTPION_CRUED_OIL_PETROLEUM_PRODUCTS: 'FUEL_CONSUMTPION_CRUED_OIL_PETROLEUM_PRODUCTS',
   FUEL_CONSUMTPION_NATURAL_GAS: 'FUEL_CONSUMTPION_NATURAL_GAS',
   FUEL_CONSUMTPION_OTHER_FOSSIL_SOURCES: 'FUEL_CONSUMTPION_OTHER_FOSSIL_SOURCES',
   FUEL_CONSUMPTION_RENEWABLE_SOURCES: 'FUEL_CONSUMPTION_RENEWABLE_SOURCES',
   CONSUMPTION_ELECTRICITY_HEAT_STEAM_COOLING_FOSSIL_SOURCES: 'CONSUMPTION_ELECTRICITY_HEAT_STEAM_COOLING_FOSSIL_SOURCES',
   CONSUMPTION_ELECTRICITY_HEAT_STEAM_COOLING_RENEWABLE_SOURCES: 'CONSUMPTION_ELECTRICITY_HEAT_STEAM_COOLING_RENEWABLE_SOURCES',
   CONSUMPTION_NUCLEAR_PRODUCTS: 'CONSUMPTION_NUCLEAR_PRODUCTS',
   CONSUMPTION_SELF_GENERATED_NON_FUEL_RENEWABLE_ENERGY: 'CONSUMPTION_SELF_GENERATED_NON_FUEL_RENEWABLE_ENERGY',
   ENERGY_PRODUCTION_RENEWABLE: 'ENERGY_PRODUCTION_RENEWABLE',
   ENERGY_PRODUCTION_NON_RENEWABLE: 'ENERGY_PRODUCTION_NON_RENEWABLE',
} as const;

export const ReportType = {
   REGULAR: 'regular',
   AUDIT: 'audit',
} as const;
