import { PropTypes } from 'prop-types';
import { UserContext } from 'context/index';
import facade from 'context/UserContext/facade';

export const UserContextComponent = function ({ children }) {
  return <UserContext.Provider value={facade.context$}>{children}</UserContext.Provider>;
};

UserContextComponent.propTypes = {
  children: PropTypes.node.isRequired,
};

export default UserContextComponent;
