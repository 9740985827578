import { applyTransaction } from '@datorama/akita';
import { v4 as uuidv4 } from 'uuid';
import { query } from 'state/Design/query';
import store from 'state/Design/store';
import baseClient from 'utils/axiosBaseClient';

export default class DesignService {
  constructor() {
    this.baseClient = baseClient;
    this.store = store;
    this.query = query;
  }

  getDesign() {
    return this.baseClient
      .get(`/auth/design`)
      .then((resp) =>
        applyTransaction(() => {
          this.store.update(resp.data);
          this.store.setLoading(false);
        })
      )
      .catch((error) => {
        applyTransaction(() => {
          this.store.setError({
            code: error?.response?.status ?? error?.response?.data?.code,
            variant: 'error',
            message: `${error?.response?.data?.message ?? error.message ?? ''} ${error?.response?.data?.error ?? ''}`,
            key: `${this.store?.storeName}#${uuidv4()}`,
            store: this.store?.storeName,
          });
          this.store.setLoading(false);
        });
      });
  }
}
