import { combineQueries, createEntityQuery, Order } from '@datorama/akita';
import { map } from 'rxjs';
import { flatScopedEntitiesResult$ } from 'state/KPISet/query';
import store from './store';

export const query = createEntityQuery(store, {
   sortBy: 'position',
   sortByOrder: Order.ASC,
});

export const allEntities$ = query.selectAll();

export const entities$ = combineQueries([allEntities$, flatScopedEntitiesResult$]).pipe(
   map(([kpis, scopingResults]) =>
      kpis.map((kpi) => ({
         ...scopingResults.find((result) => result.type === 'kpi' && result.id === kpi.id),
         ...kpi,
      }))
   )
);

export const activeEntity$ = combineQueries([query.selectActive(), flatScopedEntitiesResult$]).pipe(
   map(([activeKPI, scopingResults]) => ({
      ...scopingResults.find((result) => result.type === 'kpi' && result.id === activeKPI?.id),
      ...activeKPI,
   }))
);

export const activeEntityId$ = query.selectActiveId();
export const loading$ = combineQueries([query.selectLoading(), entities$, activeEntity$]).pipe(map(([isLoading]) => isLoading));
