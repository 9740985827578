import { useObservableState } from 'observable-hooks';
import facade from './facade';

const defaults = {
   entities: [],
   entitiesWithValues: [],
   entitiesTableColumns: [],
   activeEntity: null,
   activeMeta: null,
   activeFieldGroup: null,
   activeRow: null,
};

export default function useKPIFieldsFacade() {
   return {
      state: new Proxy(
         {},
         {
            /* eslint-disable react-hooks/rules-of-hooks */
            get: (target, prop) => {
               if (prop === 'error') {
                  return useObservableState(facade.query.selectError());
               }
               if (prop === 'isLoading') {
                  return useObservableState(facade.loading$, true);
               }
               if (prop === 'entities') {
                  return useObservableState(facade.entitiesWithoutValues$, defaults[prop]);
               }
               if (prop === 'entitiesWithValues') {
                  return useObservableState(facade.entities$, defaults[prop]);
               }
               if (prop === 'entitiesTableColumns') {
                  return useObservableState(facade.entitiesTypeTableColumn$, defaults[prop]);
               }
               return facade[`${prop}$`] ? useObservableState(facade[`${prop}$`], defaults[prop]) : null;
            },
            /* eslint-enable react-hooks/rules-of-hooks */
         }
      ),
      api: facade.api,
      query: facade.query,
   };
}
