import { PropTypes } from 'prop-types';
import facade from 'context/ApplicationContext/facade';
import { ApplicationContext } from 'context/index';

const ApplicationContextComponent = function ({ children }) {
  return <ApplicationContext.Provider value={facade.context$}>{children}</ApplicationContext.Provider>;
};

ApplicationContextComponent.propTypes = {
  children: PropTypes.element.isRequired,
};

export default ApplicationContextComponent;
