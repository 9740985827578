import facade from 'context/OrganisationContext/facade';
import { useObservableState } from 'observable-hooks';
import { OrganisationType } from 'utils/enum';

const defaults = {
   theme: null,
   activeOrganisationId: null,
   characteristics: [],
   consolidationMethod: 'FULL',
   currency: null,
   currencyCode: null,
   customization: null,
   emissionUnit: 'kg',
   organisationId: null,
   parentIds: [],
   reportingStandards: [],
   results: [],
   subsidiaries: [],
   subsidiaryIds: [],
   type: OrganisationType.ORGANIZATION,
   allowDataEntry: true,
};

export default function useOrganisationContext() {
   return {
      state: new Proxy(
         {},
         {
            /* eslint-disable react-hooks/rules-of-hooks */
            get: (target, prop) => {
               if (prop === 'error') {
                  return useObservableState(facade.query.selectError());
               }
               if (prop === 'isLoading') {
                  return useObservableState(facade.loading$, true);
               }
               if (prop === 'context') {
                  return useObservableState(facade.reducedContext$);
               }
               return facade[`${prop}$`] ? useObservableState(facade[`${prop}$`], defaults[prop]) : null;
            },
            /* eslint-enable react-hooks/rules-of-hooks */
         }
      ),
      setActiveOrganisation: facade.api.setActiveOrganisation,
   };
}
