import { periodContextQuery } from 'context/PeriodContext/query';
import CRUDService from 'state/CRUDService';
import UsersService from 'state/User/service';
import { query } from './query';
import store from './store';

export default class PeriodsService extends CRUDService {
   constructor() {
      if (!PeriodsService.instance) {
         super('periods', store, query, [], false, false, undefined, false);
         this.usersService = new UsersService();
         PeriodsService.instance = this;
      }

      // Service shall be instantiated only once, because otherwise the observable will be created for each service instance
       
      return PeriodsService.instance;
   }

    
   async updatePeriodContextIfModified(entityId) {
      const periodId = periodContextQuery.getValue().id;
      if (entityId === periodId) {
         this.usersService.getLoggedInUser();
      }
   }

   async updateEntity(entityId, changes) {
      const updateResult = await super.updateEntity(entityId, changes);
      this.updatePeriodContextIfModified(entityId);
      return updateResult;
   }

   async rollForward(dataToRollforward, startPeriod, targetPeriod) {
      return this.httpClient
         .post(`/${this.version}/periods/rollforward`, { dataToRollforward, startPeriod, targetPeriod })
         .then((response) => response.data)
         .catch((err) => {
            throw new Error(err);
         });
   }

   async closePeriod(periodId, forceClosing) {
      const queryParams = new URLSearchParams();

      // use from and to instead of periodId
      // queryParams.set('from', periodContext?.activePeriod?.from);
      // queryParams.set('to', periodContext?.activePeriod?.to);
      queryParams.set('forceClosing', forceClosing);

      return this.httpClient
         .post(`/${this.version}/periods/${periodId}/close?${queryParams}`)
         .then((response) => response.data)
         .catch((err) => {
            throw new Error(err);
         });
   }
}
