import facade from './facade';

/**
 * A hook that provides a facade for interacting with the report editor.
 * This hook returns an object with various methods and properties from the facade.
 *
 * @returns {Object} An object containing methods and properties for the report editor.
 * @returns {Function} **getChannelId** - Method to get the channel ID. This should be from {@link ReportEditorService#getChannelId}.
 * @returns {Function} **onReady** - Callback function to be called when the editor is ready. This should be from {@link ReportEditorService#onReady}.
 * @returns {Function} **checkPendingActions** - Method to check for any pending actions. This should be from {@link ReportEditorService#checkPendingActions}.
 * @returns {Function} **onError** - Callback function to handle errors. This should be from {@link ReportEditorService#onError}.
 * @returns {Function} **onMention** - Callback function to handle the mention feature logic. This should be from {@link ReportEditorService#onMention}.
 * @returns {Function} **useSetConfig** - Hook to manage the configuration of the editor. This should be from {@link useSetConfig}.
 */
export function useReportEditorFacade() {
   return {
      getChannelId: facade.channelId,
      onReady: facade.onReady,
      checkPendingActions: facade.checkPendingActions,
      onError: facade.onError,
      onMention: facade.onMention,
      useSetConfig: facade.useSetConfig,
   };
}
