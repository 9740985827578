import { DiagrammType } from 'utils/enum';

export const layoutPropsToSave = ['i', 'x', 'y', 'w', 'h', 'minW', 'minH', 'maxW', 'maxH'];

export const layoutConfig = {
   rowHeight: 200,
   columns: 4,
   sizes: [
      { w: 1, h: 1, minW: 1, minH: 1, maxW: 2, maxH: 1 },
      { w: 2, h: 2, minW: 2, minH: 2, maxW: 4, maxH: 2 },
   ],
};

export const sizeLimitations = {
   [layoutConfig.sizes[0]]: [DiagrammType.NUMBER, DiagrammType.PERCENT],
};

export const adjustLayouts = ({ layouts = [], charts = [], isPredefined = false }) => {
   return !isPredefined
      ? charts.map((chart) => {
           const isSmallSize = sizeLimitations[layoutConfig.sizes[0]].includes(chart?.type);
           const isLargeSize = !isSmallSize;
           const _layout = layouts.find((layout) => layout?.i === chart?.id?.toString()) ?? {};
           const layout = { ..._layout };

           if (!layout?.i) {
              layout.i = chart?.id?.toString();
           }
           if (isSmallSize) {
              // fill in missing values
              Object.keys(layoutConfig.sizes[0]).forEach((key) => {
                 if (!layout[key]) {
                    layout[key] = layoutConfig.sizes[0][key];
                 }
              });
           }
           if (isLargeSize) {
               // adjust for old layout version
              if ((layout?.w === 1 && layout?.h === 1) || (layout?.w === 2 && layout?.h === 1)) {
                 const scale = 2;
                 layout.w *= scale;
                 layout.minW = layoutConfig.sizes[1].minW;
                 layout.maxW = layoutConfig.sizes[1].maxW;
                 layout.h *= scale;
                 layout.minH = layoutConfig.sizes[1].minH;
                 layout.maxH = layoutConfig.sizes[1].maxH;
                 layout.x *= scale;
                 layout.y *= scale;
              }
              // fill in missing values
              Object.keys(layoutConfig.sizes[1]).forEach((key) => {
                 if (!layout[key]) {
                    layout[key] = layoutConfig.sizes[1][key];
                 }
              });
           }
           // fill in missing coordinates (required), library auto-adjusts position when x or y is 0
           layout.x = layout?.x ?? 0;
           layout.y = layout?.y ?? layouts.reduce((acc, layout) => Math.max(acc, (layout?.y ?? 0) + (layout?.h ?? 2)), 0) + 1;

           return layout;
        })
      : // create layout for predefined charts like EU-Taonomy
        charts.map((chart, index) => ({
           i: chart?.id?.toString() ?? index.toString(),
           ...(sizeLimitations[layoutConfig.sizes[0]].includes(chart?.type) ? layoutConfig.sizes[0] : layoutConfig.sizes[1]),
           x: (index * 2) % layoutConfig.columns,
           y: 0,
        }));
};
