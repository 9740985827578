import Icon from 'components/Icon';

function EnvoriaLoadingIcon() {
  return (
    <Icon
      icon="envoria-icon"
      fontSize={45}
      style={{ animation: 'toColor 1.75s linear infinite', display: 'block', margin: 'auto', height: '100%' }}
    />
  );
}

export default EnvoriaLoadingIcon;
