import { context$, loading$, query } from 'context/ApplicationContext/query';
import ApplicationService from 'state/Application/service';

const api = new ApplicationService();

const facade = {
   api,
   query,
   context$,
   loading$,
};

export default facade;
