import { RevisionTracker } from 'ckeditor5-premium-features';
import axiosClient from '../../utils/axiosAPIClient';

import CKEditorInspector from '@ckeditor/ckeditor5-inspector';
import ReportDocumentsService from 'state/ReportDocument/service';

const service = new ReportDocumentsService();
export default class ReportEditorService {
   getChannelId() {
      return new ReportDocumentsService().getActiveDocumentId();
   }

   /**
    * Checks if there are any pending actions in the editor. If there are, it prevents the default action and sets the returnValue of the event to true.
    *
    * @param {React.MutableRefObject} editorRef - Reference to the editor.
    * @param {Event} domEvt - The DOM event that triggered the check.
    *
    * The function performs the following actions:
    * - Checks if there are any pending actions in the editor.
    * - If there are pending actions, it prevents the default action of the event and sets the returnValue of the event to true.
    */
   checkPendingActions(editorRef, domEvt) {
      if (editorRef.current && editorRef.current.plugins.get('PendingActions').hasAny) {
         domEvt.preventDefault();
      }
   }

   /**
    * Returns a function to handle editor errors.
    * @returns {Function} A function that takes three parameters:
    * @property {import("ckeditor5").Editor} editor - The editor instance.
    * @property {React.MutableRefObject} editorRef - Reference to the editor.
    * @property {React.MutableRefObject} sidebarElementRef - Reference to the sidebar element.
    *
    * The returned function performs the following actions:
    * - Logs the error message to the console.
    * - If the editor is about to restart, it removes the toolbar element.
    * - Displays the error message in a snackbar notification with 'error' variant.
    */
   onError() {
      return (error, editor, enqueueSnackbar) => {
         console.error(error);
         if (editor.willEditorRestart) {
            editor.ui?.view?.menubar?.element.remove();
            editor.ui?.view?.toolbar?.element.remove();
         }
         enqueueSnackbar(error.toString(), {
            key: 'ckeditor_error',
            variant: 'error',
         });
      };
   }

   /**
    * This method is called when the CKEditor instance is ready to use.
    * It inserts the toolbar before the editable area and sets the display mode based on the window's width.
    *
    * @returns {Function} A function that takes three parameters:
    * @property {import("ckeditor5").Editor} editor - The editor instance.
    * @property {React.MutableRefObject} editorRef - Reference to the editor.
    * @property {function} toggleSidebarWidth - Reference to the sidebar toggle function.
    * @property {React.MutableRefObject} sidebarElementRef - Reference to the sidebar element.
    *
    * The returned function performs the following actions:
    * - Inserts the toolbar element before the editable element in the DOM.
    * - Logs a message to the console indicating that the editor is ready to use.
    * - Assigns the CKEditor instance to the current property of the editorRef object.
    * - Calls the refreshDisplayMode method with the editorRef and sidebarElementRef as arguments.
    */
   onReady() {
      return (editor) => {
         if (['test', 'local'].includes(process.env.NODE_ENV) || process.env.REACT_APP_DOMAIN) {
            CKEditorInspector.attach(editor);
         }
      };
   }

   /**
    * Perform autosave functionality by checking for revisions and updating if necessary.
    * @param {import("ckeditor5").Editor} editor - The editor instance.
    * @returns {Promise<boolean>} - A promise that resolves to true if autosave is successful.
    */
   async onAutosave(editor) {
      const revisionTracker = editor.plugins.get(RevisionTracker);

      if (!revisionTracker?.currentRevision) {
         return false;
      }

      const currentRevision = revisionTracker.currentRevision;
      const oldRevisionVersion = currentRevision.toVersion;
      await revisionTracker.update();

      const documentData = await revisionTracker.getRevisionDocumentData(revisionTracker.currentRevision);

      await service.saveDocumentRevision(service.getActiveDocumentId(), documentData);

      return oldRevisionVersion !== currentRevision.toVersion;
   }

   /**
    * Asynchronously fetches data based on the provided parameters.
    *
    * @param {string} term - The search term.
    * @param {string[]} [kinds] - The kinds of feeds to fetch. Can be an array of strings.
    * @param {string} [from] - The start date for the search in ISO format.
    * @param {string} [to] - The end date for the search in ISO format.
    * @param {number} [organisationId] - The ID of the organisation.
    * @returns {Promise<Array<{id: string, text: string, kpi_id: number, kpi_slug: string}>>} A promise that resolves to an array of mention data.
    *
    * The response data from the axios call is an array of objects with the following structure:
    * @typedef {Object} ResponseData
    * @property {Array} items
    * @property {Array} rows
    * @property {boolean} hasComments
    * @property {number} kpiFieldId
    * @property {number|undefined} fpCalculationFieldId
    * @property {boolean|undefined} canAttachFile
    * @property {Array} metas
    * @property {string} unit
    * @property {string|null} currency
    * @property {number} kpiValuesCount
    * @property {boolean} hasValues
    * @property {Array} attachments
    * @property {Object} kpiFieldType
    * @property {string} kpiFieldType.name
    * @property {Array} errors
    * @property {Array} validationRules
    * @property {string|null} justification
    * @property {number|null} rowId
    * @property {any} value
    * @property {Array} values
    * @property {number|undefined} kpiValuesSum
    * @property {number|undefined} kpiValuesSumCurrent
    * @property {string|null} reviewer
    * @property {number|null} reviewerId
    * @property {string|null} approver
    * @property {number|null} approverId
    * @property {boolean} isEstimated
    * @property {boolean} hasValueItems
    * @property {boolean} hasVersions
    * @property {string} status
    */
   async onMention(term = '') {
      const searchParams = new URLSearchParams({
         text: term.toLowerCase(),
      });

      return axiosClient.get(`/v2/users?${searchParams}`).then(({ data }) =>
         data.data.map((e) => ({
            id: '@' + e.userName,
            text: `@${e.firstName} ${e.lastName}`,
         }))
      );
   }
}
