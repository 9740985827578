import { getEntityStoreByName, getStoreByName } from '@datorama/akita';
import { shouldPolyfill } from '@formatjs/intl-datetimeformat/should-polyfill';
import { Button, CssBaseline, StyledEngineProvider, useMediaQuery } from '@mui/material';
import * as muiLocales from '@mui/material/locale';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import * as muiDataGridLocales from '@mui/x-data-grid-premium/locales';
import * as Sentry from '@sentry/react';
import axios from 'axios';
import ability from 'casl/ability';
import { AbilityContext } from 'casl/can';
import ThemedSnackbar from 'components/Snackbar/ThemeWrapper';
import FullPageLoadingIndicator from 'components/v2/FullPageLoadingIndicator';
import ApplicationContextComponent from 'context/ApplicationContext';
import useApplicationContext from 'context/ApplicationContext/hook';
import LocaleContextComponent from 'context/LocaleContext';
import { OrganisationContextComponent } from 'context/OrganisationContext';
import useOrganisationContext from 'context/OrganisationContext/hook';
import { PeriodContextComponent } from 'context/PeriodContext';
import useUserContext from 'context/UserContext/hook';
import { flatten } from 'flat';
import { getUserLocale } from 'get-user-locale';
import { merge } from 'lodash-es';
import { SnackbarProvider, closeSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage, IntlProvider } from 'react-intl';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import generateRoutes from 'routes';
import useDesignFacade from 'state/Design/hook';
import envoriaTheme from 'themes/envoriaTheme';

async function polyfillDateTimeFormat(locale) {
   const unsupportedLocale = shouldPolyfill(locale);
   // This locale is supported
   if (!unsupportedLocale) {
      return;
   }
   // Load the polyfill 1st BEFORE loading data
   await import('@formatjs/intl-datetimeformat/polyfill-force');

   // Parallelize CLDR data loading
   const dataPolyfills = [
      import('@formatjs/intl-datetimeformat/add-all-tz'),
      import(`@formatjs/intl-datetimeformat/locale-data/${unsupportedLocale}`),
   ];
   await Promise.all(dataPolyfills);
}

async function polyfillDisplayNames(locale) {
   const unsupportedLocale = shouldPolyfill(locale);
   // This locale is supported
   if (!unsupportedLocale) {
      return;
   }
   // Load the polyfill 1st BEFORE loading data
   await import('@formatjs/intl-displaynames/polyfill-force');
   await import(`@formatjs/intl-displaynames/locale-data/${unsupportedLocale}`);
}

const defaultLocale = 'en-US';

let languageWithoutRegionCode = getUserLocale().substring(0, 2);

try {
   require.resolve(`lang/${languageWithoutRegionCode}.json`);
} catch {
   languageWithoutRegionCode = defaultLocale.substring(0, 2);
}

axios.defaults.withCredentials = true;
axios.defaults.headers.common['Accept-Language'] = getUserLocale();

polyfillDateTimeFormat(languageWithoutRegionCode);
polyfillDisplayNames(languageWithoutRegionCode);

function App() {
   const {
      state: { locale: userLocale, language },
   } = useUserContext();
   const {
      state: { design, isLoading: isDesignLoading },
   } = useDesignFacade();
   const {
      state: { customization, theme },
   } = useOrganisationContext();
   const {
      state: { isLoading: isApplicationContextLoading },
   } = useApplicationContext();

   const [messages, setMessages] = useState({});
   const [locale, setLocale] = useState(defaultLocale);

   useEffect(() => {
      async function loadMessages(localeOrLanguageCode) {
         let tempMessages = {};
         let returnLanguage;

         try {
            require.resolve(`lang/${localeOrLanguageCode.substring(0, 2)}.json`);
            tempMessages = await import(`lang/${localeOrLanguageCode.substring(0, 2)}.json`);
            returnLanguage = localeOrLanguageCode;
         } catch {
            tempMessages = await import(`lang/${defaultLocale.substring(0, 2)}.json`);
            returnLanguage = defaultLocale;
         }

         const customMessages = customization?.messages?.[language ? locale.substring(0, 2) : languageWithoutRegionCode] ?? {};

         setMessages(merge(tempMessages, customMessages));
         setLocale(returnLanguage);

         return returnLanguage;
      }

      if (userLocale) {
         loadMessages(userLocale)
            .then((lang_) => {
               axios.defaults.headers.common['Accept-Language'] = lang_;
               document.documentElement.lang = lang_;
               return undefined;
            })
            .catch(() => {
               // do nothing
            });
      } else {
         loadMessages(languageWithoutRegionCode)
            .then((lang_) => {
               axios.defaults.headers.common['Accept-Language'] = lang_;
               document.documentElement.lang = lang_;
               return undefined;
            })
            .catch(() => {
               // do nothing
            });
      }
   }, [language, locale, customization?.messages, userLocale]);

   const onClickDismiss = useCallback((key) => {
      if (key) {
         closeSnackbar(key);
      }
      if ((key ?? '').toString().includes('#')) {
         const storeName = key.toString().substring(0, key.toString().indexOf('#'));

         if (storeName) {
            const store = getEntityStoreByName(storeName) ?? getStoreByName(storeName);

            if (store) {
               const currentError = store.getValue()?.error;
               if (currentError?.key === key) {
                  store.setError(null);
               }
            }
         }
      }
   }, []);

   const snackbarAction = useCallback(
      (key) => (
         <Button onClick={() => onClickDismiss(key)} sx={{ textTransform: 'uppercase', color: 'currentColor' }}>
            <FormattedMessage id="snackbars.dismiss" defaultMessage="Dismiss" />
         </Button>
      ),
      [onClickDismiss]
   );

   const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
   const mode = prefersDarkMode ? 'dark' : 'light';

   const modeTheme = useMemo(
      () =>
         createTheme(
            envoriaTheme(mode, theme ?? design?.theme),
            userLocale && locale && muiLocales?.[locale.replace('-', '')] ? muiLocales?.[locale.replace('-', '')] : muiLocales.enUS,
            userLocale && locale && muiLocales?.[locale.replace('-', '')] ? muiDataGridLocales?.[locale.replace('-', '')] : muiDataGridLocales.enUS
         ),
      [mode, theme, userLocale, design?.theme, locale]
   );

   const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

   const router = sentryCreateBrowserRouter(generateRoutes());

   if (isDesignLoading || isApplicationContextLoading) {
      return <FullPageLoadingIndicator />;
   }

   return (
      <>
         <Helmet defaultTitle="envoria">
            <title>{design?.title}</title>
         </Helmet>
         <LocaleContextComponent>
            <ApplicationContextComponent>
               <OrganisationContextComponent>
                  <PeriodContextComponent>
                     <IntlProvider
                        locale={userLocale ?? languageWithoutRegionCode}
                        messages={flatten(messages)}
                        defaultLocale={defaultLocale.substring(0, 2)}
                     >
                        <StyledEngineProvider injectFirst>
                           <ThemeProvider theme={modeTheme}>
                              <SnackbarProvider
                                 dense
                                 maxSnack={3}
                                 preventDuplicate
                                 action={snackbarAction}
                                 autoHideDuration={5000}
                                 Components={{
                                    default: ThemedSnackbar,
                                    success: ThemedSnackbar,
                                    error: ThemedSnackbar,
                                    warning: ThemedSnackbar,
                                    info: ThemedSnackbar,
                                 }}
                              >
                                 <AbilityContext.Provider value={ability}>
                                    <CssBaseline />
                                    <RouterProvider router={router} />
                                 </AbilityContext.Provider>
                              </SnackbarProvider>
                           </ThemeProvider>
                        </StyledEngineProvider>
                     </IntlProvider>
                  </PeriodContextComponent>
               </OrganisationContextComponent>
            </ApplicationContextComponent>
         </LocaleContextComponent>
      </>
   );
}

export default App;
