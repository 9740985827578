import { activeEntity$, activeEntityId$, allEntities$, entities$, loading$, query } from './query';
import KPISubsService from './service';

const api = new KPISubsService();

const facade = {
   api,
   query,
   entities$,
   activeEntity$,
   allEntities$,
   loading$,
   activeEntityId$,
};

export default facade;
