import ReportEditor from 'components/ReportEditor';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useReportDocumentsFacade from 'state/ReportDocument/hook';

const ReportEditorPage = () => {
   const { reportId, documentId } = useParams();
   const { api: reportDocumentsAPI } = useReportDocumentsFacade();

   useEffect(() => {
      if (reportId && documentId) {
         reportDocumentsAPI.setActiveEntity(Number.parseInt(documentId, 10));
      }
   }, [documentId, reportDocumentsAPI, reportId]);

   return <ReportEditor />;
};

export default ReportEditorPage;
