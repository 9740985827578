import { applyTransaction, combineQueries, filterNilValue } from '@datorama/akita';
import { activeOrganisationId$ } from 'context/OrganisationContext/query';
import { includeSubsidiaries$ } from 'context/UserContext/query';
import { formatISO } from 'date-fns';
import { isEqual, omit } from 'lodash-es';
import { distinctUntilChanged, map, of } from 'rxjs';
import CRUDService from 'state/CRUDService';
import kpiStore from 'state/KPI/store';
import kpiAreaStore from 'state/KPIArea/store';
import kpiContentStore from 'state/KPIContent/store';
import kpiFieldStore from 'state/KPIField/store';
import kpiSubStore from 'state/KPISub/store';
import { query as uiQuery } from 'state/UI/query';
import { activeEntity$ as activeUIPeriod$ } from 'state/UIPeriod/query';
import { entities$, query } from './query';
import store from './store';

export default class KPISetService extends CRUDService {
   constructor() {
      if (!KPISetService.instance) {
         super('kpiset', store, query, [], true, true, undefined, false, false, false);

         this.statisticsQueryParamsObservable = this.getStatisticsQueryParams();

         entities$.subscribe((kpiSet) => {
            if (this.getLoading() === false) {
               kpiAreaStore.setLoading(true);
               kpiStore.setLoading(true);
               kpiSubStore.setLoading(true);
               kpiContentStore.setLoading(true);
               kpiFieldStore.setLoading(true);

               const kpiFieldIds = kpiSet.flatMap((kpiArea) =>
                  (kpiArea?.kpis ?? []).flatMap((kpi) =>
                     kpi.kpiSubs.flatMap((kpiSub) => kpiSub.kpiContents.flatMap((kpiContent) => kpiContent.kpiFields.map((kpiField) => kpiField.id)))
                  )
               );

               const kpiContentIds = kpiSet.flatMap((kpiArea) =>
                  (kpiArea?.kpis ?? []).flatMap((kpi) => kpi.kpiSubs.flatMap((kpiSub) => kpiSub.kpiContents.map((kpiContent) => kpiContent.id)))
               );

               const kpiSubIds = kpiSet.flatMap((kpiArea) => (kpiArea?.kpis ?? []).flatMap((kpi) => kpi.kpiSubs.flatMap((kpiSub) => kpiSub.id)));

               const kpiIds = kpiSet.flatMap((kpiArea) => (kpiArea?.kpis ?? []).flatMap((kpi) => kpi.id));

               const kpiAreaIds = kpiSet.map((kpiArea) => kpiArea.id);

               return applyTransaction(() => {
                  kpiFieldStore.remove(({ id }) => !kpiFieldIds.includes(id));
                  kpiContentStore.remove(({ id }) => !kpiContentIds.includes(id));
                  kpiSubStore.remove(({ id }) => !kpiSubIds.includes(id));
                  kpiStore.remove(({ id }) => !kpiIds.includes(id));
                  kpiAreaStore.remove(({ id }) => !kpiAreaIds.includes(id));

                  kpiAreaStore.upsertMany(kpiSet.map((kpiArea) => omit(kpiArea, ['kpis'])));

                  kpiSet.forEach((kpiArea) => {
                     if (Array.isArray(kpiArea?.kpis)) {
                        kpiStore.upsertMany(
                           kpiArea.kpis.map((kpi) => omit({ ...kpi, kpiArea: { ...kpi.kpiArea, id: kpiArea.id, slug: kpiArea.slug } }, ['kpiSubs']))
                        );

                        kpiArea.kpis.forEach((kpi) => {
                           if (Array.isArray(kpi?.kpiSubs)) {
                              kpiSubStore.upsertMany(
                                 kpi.kpiSubs.map((kpiSub) =>
                                    omit(
                                       {
                                          ...kpiSub,
                                          kpi: {
                                             id: kpi.id,
                                             slug: kpi.slug,
                                             position: kpi.position,
                                             kpiArea: { id: kpiArea.id, slug: kpiArea.slug, position: kpiArea.position },
                                          },
                                       },
                                       ['kpiContents']
                                    )
                                 )
                              );

                              kpi.kpiSubs.forEach((kpiSub) => {
                                 if (Array.isArray(kpiSub?.kpiContents)) {
                                    kpiContentStore.upsertMany(
                                       kpiSub.kpiContents.map((kpiContent) =>
                                          omit({ ...kpiContent, kpiSub: { id: kpiSub.id, slug: kpiSub.slug } }, ['kpiFields'])
                                       )
                                    );

                                    kpiSub.kpiContents.forEach((kpiContent) => {
                                       if (Array.isArray(kpiContent?.kpiFields)) {
                                          kpiFieldStore.upsertMany(
                                             kpiContent.kpiFields.map((kpiField) => ({
                                                ...omit(kpiField, ['rows', 'formulaUnit', 'errors']),
                                                ...(kpiField?.formulaUnit ? { formulaUnit: kpiField?.formulaUnit } : {}),
                                                kpiContent: {
                                                   id: kpiContent.id,
                                                   slug: kpiContent.slug,
                                                   position: kpiContent.position,
                                                   kpiSub: {
                                                      id: kpiSub.id,
                                                      slug: kpiSub.slug,
                                                      kpi: {
                                                         id: kpi.id,
                                                         slug: kpi.slug,
                                                         kpiArea: {
                                                            id: kpiArea.id,
                                                            slug: kpiArea.slug,
                                                         },
                                                      },
                                                   },
                                                },
                                             }))
                                          );

                                          kpiContent.kpiFields
                                             .filter(({ rows }) => rows?.length > 0)
                                             .forEach((kpiField) =>
                                                kpiFieldStore.update(kpiField.id, (entity) => ({
                                                   ...entity,
                                                   rows: [
                                                      ...(entity?.rows ?? []),
                                                      ...kpiField.rows.filter(({ id }) => !(entity?.rows ?? []).map(({ id }) => id).includes(id)),
                                                   ],
                                                   kpiContent: {
                                                      id: kpiContent.id,
                                                      slug: kpiContent.slug,
                                                      kpiSub: {
                                                         id: kpiSub.id,
                                                         slug: kpiSub.slug,
                                                         kpi: {
                                                            id: kpi.id,
                                                            slug: kpi.slug,
                                                            kpiArea: {
                                                               id: kpiArea.id,
                                                               slug: kpiArea.slug,
                                                            },
                                                         },
                                                      },
                                                   },
                                                }))
                                             );
                                       }
                                    });
                                 }
                              });
                           }
                        });
                     }
                  });

                  kpiAreaStore.setLoading(false);
                  kpiStore.setLoading(false);
                  kpiSubStore.setLoading(false);
                  kpiContentStore.setLoading(false);
                  kpiFieldStore.setLoading(false);
               });
            }
            return undefined;
         });

         KPISetService.instance = this;
      }

      // Service shall be instantiated only once, because otherwise the observable will be created for each service instance

      return KPISetService.instance;
   }

   async import(file) {
      const formData = new FormData();
      formData.append('file', file);

      try {
         const result = await this.httpClient.post(`/${this.version}/${this.entityName}/import/json`, formData, {
            'Content-Type': 'multipart/form-data',
         });

         return result.data;
      } catch (err) {
         throw new Error(err?.response?.data?.message ?? err?.message ?? err);
      }
   }

   async export(reportingStandardId, kpiAreas = []) {
      const searchParams = new URLSearchParams();

      if (reportingStandardId) {
         searchParams.set('reportingStandardId', reportingStandardId);
      }
      if (kpiAreas?.length > 0) {
         searchParams.set('kpiAreaIds', kpiAreas.join());
      }

      return this.httpClient
         .get(`/${this.version}/${this.entityName}/export/json?${searchParams}`)
         .then((resp) => resp)
         .catch((error) => {
            this.setError(error);
         });
   }

   async exportValues(
      reportingStandardIds,
      from,
      to,
      includeSubsidiaryValues,
      kpiAreas = [],
      kpis = [],
      onlyApproved = false,
      reportGroupByOrganisation = false,
      emptyFields = false,
      tags,
      formatType = 'excel',
      reportType = 'regular'
   ) {
      const searchParams = new URLSearchParams();

      if (reportingStandardIds) {
         searchParams.set('reportingStandardIds', reportingStandardIds.join(','));
      }
      if (kpiAreas?.length > 0) {
         searchParams.set('kpiAreaIds', kpiAreas.join(','));
      }
      if (kpis?.length > 0) {
         searchParams.set('kpis', kpis.join(','));
      }

      if (tags?.length > 0) {
         searchParams.set('tag', tags.join(','));
      }

      searchParams.set('from', from);
      searchParams.set('to', to);
      searchParams.set('aggregationLevel', includeSubsidiaryValues && reportGroupByOrganisation ? 'entity' : 'group');
      searchParams.set('includeSubsidiaries', includeSubsidiaryValues ?? false);
      searchParams.set('onlyApproved', onlyApproved);
      searchParams.set('emptyFields', emptyFields);
      searchParams.set('reportType', reportType);
      searchParams.set('type', formatType);

      // todo: remove this when the backend is ready
      const tmpEntityName = reportType === 'regular' ? this.entityName : 'kpivalues';
      const action = reportType === 'regular' ? 'export/excel' : 'export';

      return this.httpClient
         .get(`/${this.version}/${tmpEntityName}/${action}?${searchParams}`, { responseType: 'blob' })
         .then((resp) => resp)
         .catch((error) => {
            this.setError(error);
         });
   }

   getStatisticsQueryParams() {
      const dependencies = [];

      if (this.useOrganisationContext) {
         dependencies.push(activeOrganisationId$.pipe(filterNilValue()));
      }
      dependencies.push(activeUIPeriod$.pipe(filterNilValue()));
      dependencies.push(includeSubsidiaries$.pipe(filterNilValue()));
      dependencies.push(uiQuery.select('statisticsFilters').pipe(filterNilValue()));

      if (dependencies.length) {
         return combineQueries(dependencies).pipe(
            distinctUntilChanged(isEqual),
            map((values) => {
               const urlParamsSubClass = new URLSearchParams();

               if (this.useScope === true) {
                  urlParamsSubClass.set('scope', true);
               } else if (this.useScope === false) {
                  urlParamsSubClass.set('scope', false);
               } else {
                  urlParamsSubClass.set('scope', 'any');
               }

               if (values.length === dependencies.length) {
                  const uiPeriod = values.find((value) => value && Object.hasOwn(value, 'from') && Object.hasOwn(value, 'to'));
                  const includeSubsidiaries = values.find((value) => typeof value === 'boolean');
                  const organisationId = values.find((value) => typeof value === 'number');

                  if (organisationId) {
                     urlParamsSubClass.set('organisationId', organisationId);
                  }

                  if (uiPeriod) {
                     urlParamsSubClass.set('from', formatISO(uiPeriod.from, { representation: 'date' }));
                     urlParamsSubClass.set('to', formatISO(uiPeriod.to, { representation: 'date' }));
                  }

                  if (includeSubsidiaries) {
                     urlParamsSubClass.set('includeSubsidiaries', includeSubsidiaries);
                  }

                  const filters = values.find(
                     (value) =>
                        typeof value === 'object' &&
                        Object.keys(value).every((key) => ['periods', 'reportingStandards', 'organisations', 'tags'].includes(key))
                  );
                  if (filters) {
                     const groupedFilter = Object.entries(filters).reduce((acc, [kind, filterItems]) => {
                        const formatData = (date) => formatISO(typeof date === 'string' ? new Date(date) : date, { representation: 'date' });
                        switch (kind) {
                           case 'periods':
                              return {
                                 ...acc,
                                 from: filterItems.map((filter) => formatData(filter?.from)),
                                 to: filterItems.map((filter) => formatData(filter?.to)),
                              };
                           case 'reportingStandards':
                              return {
                                 ...acc,
                                 reportingStandardId: filterItems.map((filter) => filter?.id),
                              };
                           case 'organisations':
                              return {
                                 ...acc,
                                 organisationId: filterItems.map((filter) => filter?.id),
                              };
                           case 'tags':
                              return {
                                 ...acc,
                                 tag: filterItems.map((filter) => filter?.name),
                              };
                           default:
                              return acc;
                        }
                     }, {});

                     for (const [key, value] of Object.entries(groupedFilter)) {
                        if (value.length) {
                           urlParamsSubClass.set(key, value.join(','));
                        }
                     }
                  }

                  if (uiPeriod && organisationId) {
                     return `?${urlParamsSubClass.toString()}`;
                  }
               }

               return false;
            })
         );
      }

      const urlParams = new URLSearchParams();

      if (this.useScope === true) {
         urlParams.set('scope', true);
      } else if (this.useScope === false) {
         urlParams.set('scope', false);
      } else {
         urlParams.set('scope', 'any');
      }
      return of(`?${urlParams.toString()}`);
   }

   async getStatistics() {
      if (this.getStatisticsObservable === undefined || this.getStatisticsObservable?.closed) {
         this.getStatisticsObservable = this.statisticsQueryParamsObservable
            .pipe(filterNilValue(), distinctUntilChanged())
            .subscribe((queryString) => {
               if (typeof queryString === 'string') {
                  if (this.statisticsController) {
                     this.statisticsController.abort();
                  }

                  this.statisticsController = new AbortController();

                  this.store.setLoading(true);
                  this.httpClient
                     .get(`/${this.version}/statistics/kpisinfo${queryString}`, {
                        signal: this.statisticsController.signal,
                     })
                     .then((resp) => {
                        this.store.update({ statistics: resp.data });
                        return this.store.setLoading(false);
                     })
                     .catch((error) => {
                        this.setError(error);
                     });
               }

               return undefined;
            });
      }
   }

   setActiveNode(type = null, id = null) {
      if (!type || !id) {
         this.store.update({ activeNode: undefined });
      } else {
         this.store.update({ activeNode: { type, id } });
      }
   }

   async deleteEntity(entityId, kind) {
      if (kind === 'kpiArea') {
         return this.store.remove(entityId);
      } else if (kind === 'kpi') {
         return this.store.update((kpiAreas) =>
            kpiAreas.map((kpiArea) => ({
               ...kpiArea,
               kpis: kpiArea.kpis.filter(({ id }) => id !== entityId),
            }))
         );
      } else if (kind === 'kpiSub') {
         return this.store.update((kpiAreas) =>
            kpiAreas.map((kpiArea) => ({
               ...kpiArea,
               kpis: kpiArea.kpis.map((kpi) => ({
                  ...kpi,
                  kpiSubs: kpi.kpiSubs.filter(({ id }) => id !== entityId),
               })),
            }))
         );
      } else if (kind === 'kpiContent') {
         return this.store.update((kpiAreas) =>
            kpiAreas.map((kpiArea) => ({
               ...kpiArea,
               kpis: kpiArea.kpis.map((kpi) => ({
                  ...kpi,
                  kpiSubs: kpi.kpiSubs.map((kpiSub) => ({
                     ...kpiSub,
                     kpiContents: kpiSub.kpiContents.filter(({ id }) => id !== entityId),
                  })),
               })),
            }))
         );
      } else if (kind === 'kpiField') {
         return this.store.update((kpiAreas) =>
            kpiAreas.map((kpiArea) => ({
               ...kpiArea,
               kpis: kpiArea.kpis.map((kpi) => ({
                  ...kpi,
                  kpiSubs: kpi.kpiSubs.map((kpiSub) => ({
                     ...kpiSub,
                     kpiContents: kpiSub.kpiContents.map((kpiContent) => ({
                        ...kpiContent,
                        kpiFields: kpiContent.kpiFields.filter(({ id }) => id !== entityId),
                     })),
                  })),
               })),
            }))
         );
      }
   }

   async createOrUpdateFieldScope(kpiFieldId, kpiAreaId, existingScope, changes) {
      if (existingScope?.id > 0) {
         return this.httpClient
            .patch(`/${this.version}/kpiscopes/${existingScope.id}`, changes)
            .then((resp) =>
               this.store.update(kpiAreaId, ({ kpis }) => ({
                  kpis: kpis.map((kpi) => ({
                     ...kpi,
                     kpiSubs: kpi.kpiSubs.map((kpiSub) => ({
                        ...kpiSub,
                        kpiContents: kpiSub.kpiContents.map((kpiContent) => ({
                           ...kpiContent,
                           kpiFields: kpiContent.kpiFields.map((kpiField) => ({
                              ...kpiField,
                              kpiScopes:
                                 kpiField.id === kpiFieldId
                                    ? [resp.data, ...kpiField.kpiScopes.filter((kpiScope) => existingScope.id !== kpiScope.id)]
                                    : kpiField.kpiScopes,
                           })),
                        })),
                     })),
                  })),
               }))
            )
            .catch((error) => {
               this.setError(error);
            });
      } else {
         return this.httpClient
            .post(`/${this.version}/kpiscopes`, changes)
            .then((resp) =>
               this.store.update(kpiAreaId, ({ kpis }) => ({
                  kpis: kpis.map((kpi) => ({
                     ...kpi,
                     kpiSubs: kpi.kpiSubs.map((kpiSub) => ({
                        ...kpiSub,
                        kpiContents: kpiSub.kpiContents.map((kpiContent) => ({
                           ...kpiContent,
                           kpiFields: kpiContent.kpiFields.map((kpiField) => ({
                              ...kpiField,
                              kpiScopes: kpiField.id === kpiFieldId ? [...kpiField.kpiScopes, ...resp.data] : kpiField.kpiScopes,
                           })),
                        })),
                     })),
                  })),
               }))
            )
            .catch((error) => {
               this.setError(error);
            });
      }
   }

   unsubscribeStatistics() {
      if (this.getStatisticsObservable && !this.getStatisticsObservable.closed) {
         this.getStatisticsObservable.unsubscribe();
      }
      if (this.statisticsController) {
         this.statisticsController.abort();
      }
   }
}
