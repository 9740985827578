import { createEntityStore } from '@datorama/akita';

export default createEntityStore(
   {
      statistics: undefined,
      sort: {
         key: null,
         order: 'asc',
      },
   },
   { resettable: true, name: 'organisations', withActive: 'Active' }
);
