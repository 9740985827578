import { applyTransaction } from '@datorama/akita';
import { organisationContextQuery } from 'context/OrganisationContext/query';
import { omit, pick } from 'lodash-es';
import { tap } from 'rxjs';
import approvalCenterStore from 'state/ApprovalCenter/store';
import CRUDService from 'state/CRUDService';
import kpiFieldsStore from 'state/KPIField/store';
import kpiValuesStore from 'state/KPIValue/store';
import { KPIFieldType } from 'utils/enum';
import { query } from './query';
import store from './store';

export default class KPISubsService extends CRUDService {
   constructor() {
      super('kpisubs', store, query, [], true, true, undefined, false, true);
      this.entityObservable = new Map();
      this.kpiFieldsStore = kpiFieldsStore;
      this.kpiValuesStore = kpiValuesStore;
      this.approvalCenterStore = approvalCenterStore;
      this.organisationContextQuery = organisationContextQuery;
      this.sumsController = new AbortController();
      this.pendingSumRequest = new Set();
   }

   // This function is added here because in CRUD file we omit 'reportingStandards'.
   // Just until we clarify does 'reportingStandards' could be removed from omit func in main CRUD file.
   async persistEntityState(entity_, relevantKeys = []) {
      let entity;

      entity = omit(entity_, ['createdAt', 'updatedAt']);

      if (relevantKeys.length > 0) {
         entity = pick(entity, relevantKeys);
      }

      if (entity?.id <= 0) {
         return;
      }
      applyTransaction(() =>
         this.httpClient
            .patch(`/${this.version}/${this.entityName}/${entity.id}`, entity)
            .then((resp) => this.store.update(entity.id, resp.data))
            .catch((error) => {
               this.setError(error);
            })
      );
   }

   async #getFieldSumsInternal(entityId) {
      if (this.pendingSumRequest.has(entityId)) {
         return;
      }

      if (this.pendingSumRequest.size > 0 && !this.pendingSumRequest.has(entityId)) {
         this.sumsController.abort();
         this.sumsController = new AbortController();
         this.pendingSumRequest.clear();
      }

      this.pendingSumRequest.add(entityId);

      this.kpiValuesStore.setLoading(true);

      const organisationId = this.organisationContextQuery.getValue()?.id;

      return this.httpClient
         .get(`/${this.version}/${this.entityName}/${entityId}/fields/sums${this.queryParams ?? ''}`)
         .then((resp) =>
            applyTransaction(() => {
               this.pendingSumRequest.delete(entityId);
               this.kpiFieldsStore.upsertMany(
                  resp.data.map((kpiValueInfo) => ({
                     id: kpiValueInfo?.kpiFieldId,
                     ...pick(kpiValueInfo, [
                        'attachments',
                        'columns',
                        'rows',
                        'status',
                        'hasValueItems',
                        'hasValues',
                        'hasVersions',
                        'isEstimated',
                        'approver',
                        'reviewer',
                        'kpiValuesCount',
                        'kpiValuesSum',
                        'kpiValuesSumCurrent',
                        'metas',
                        'unit',
                        'links',
                        'currency',
                        'kpiValuesSumPreviousPeriod',
                        'validationRules',
                        'fieldGroupId',
                        'aggregation',
                     ]),
                     // NOTE: following properties are KPIValue properties and should be removed from KPIField after refactoring
                     // some components are using these properties from KPIField
                     kpiValueId: kpiValueInfo?.kpiValueId ?? null,
                     hasComments: kpiValueInfo?.hasComments ?? false,
                     errors: kpiValueInfo?.errors ?? [],
                     justification: kpiValueInfo?.justification ?? null,
                     reviewerId: kpiValueInfo?.reviewerId ?? null,
                     approverId: kpiValueInfo?.approverId ?? null,
                     rowId: kpiValueInfo?.rowId ?? null,
                     value: kpiValueInfo?.value ?? null,
                     dataType: kpiValueInfo?.dataType ?? null,
                     from: kpiValueInfo?.from ?? null,
                     to: kpiValueInfo?.to ?? null,
                  })),
                  { loading: false }
               );

               this.kpiFieldsStore.upsertMany(
                  resp.data
                     .filter(
                        (kpiValueInfo) =>
                           kpiValueInfo?.kpiValuesCount !== 1 || kpiValueInfo?.resolvedFormulaArray || kpiValueInfo?.organisationId !== organisationId
                     )
                     .map((kpiValueInfo) => ({
                        id: kpiValueInfo?.kpiFieldId,
                        ...omit(kpiValueInfo, ['kpiFieldId', 'kpiValueId']),
                     })),
                  { loading: false }
               );

               this.kpiValuesStore.remove((entity) =>
                  resp.data
                     .filter((kpiValueInfo) => kpiValueInfo?.kpiValuesCount !== 1 && !Number.isInteger(kpiValueInfo?.kpiValueId))
                     .map((kpiValueInfo) => kpiValueInfo?.kpiFieldId)
                     .includes(entity.kpiFieldId)
               );

               this.kpiValuesStore.upsertMany(
                  resp.data
                     .filter(
                        (kpiValueInfo) =>
                           Number.isInteger(kpiValueInfo?.kpiValueId) &&
                           kpiValueInfo?.kpiValuesCount === 1 &&
                           kpiValueInfo.kpiFieldType.name !== KPIFieldType.TABLE
                     )
                     .map((kpiValueInfo) => ({
                        ...omit(kpiValueInfo, [
                           'attachments',
                           'kpiValueId',
                           'kpiValuesCount',
                           'kpiValuesSum',
                           'kpiValuesSumCurrent',
                           'kpiValues',
                           'metas',
                           'kpiValuesSumPreviousPeriod',
                        ]),
                        id: kpiValueInfo?.kpiValueId,
                     })),
                  { loading: false }
               );

               this.kpiValuesStore.upsertMany(
                  resp.data
                     .filter(
                        (kpiValueInfo) =>
                           kpiValueInfo.kpiFieldType.name === KPIFieldType.TABLE &&
                           Array.isArray(kpiValueInfo.kpiValues) &&
                           kpiValueInfo.kpiValues.length > 0
                     )
                     .flatMap((kpiValueInfo) =>
                        kpiValueInfo.kpiValues.map((kpiValue) => ({
                           ...kpiValue,
                           kpiFieldId: kpiValueInfo?.kpiFieldId,
                        }))
                     ),
                  { loading: false }
               );

               this.kpiValuesStore.setLoading(false);
            })
         )
         .catch((error) => {
            this.pendingSumRequest.delete(entityId);
            this.setError(error);
         });
   }

   async getFieldSums(entityId, force = true) {
      Array.from(this.entityObservable.entries())
         .filter(([otherEntityId]) => otherEntityId !== entityId)
         .forEach(([otherEntityId, observable]) => {
            if (observable && !observable?.closed) {
               observable.unsubscribe();
            }
            this.entityObservable.delete(otherEntityId);
         });

      if (!this.entityObservable.get(entityId)) {
         return this.entityObservable.set(
            entityId,
            this.queryParamsObservable
               .pipe(
                  tap((queryString) => {
                     if (typeof queryString === 'string') {
                        return this.#getFieldSumsInternal(entityId);
                     }
                     return undefined;
                  })
               )
               .subscribe()
         );
      } else if (force) {
         return this.#getFieldSumsInternal(entityId);
      }
   }

   async setStatusApprovalCenter(setStatusValues, auditValues) {
      return this.approvalCenterStore.update((entity) => (auditValues ?? []).includes(entity?.kpiField?.id), {
         ...setStatusValues,
      });
   }

   unsubscribeFieldSums() {
      if (this.sumsController) {
         this.sumsController.abort();
      }

      this.pendingSumRequest.clear();

      for (const subscription of this.entityObservable.values()) {
         if (subscription && !subscription.closed) {
            subscription.unsubscribe();
         }
      }

      this.entityObservable.clear();
   }
}
