import { activeDocumentId$, activeEntity$, activeReportId$, entities$, loading$, query } from './query';
import ReportDocumentsService from './service';

const api = new ReportDocumentsService();

const facade = {
   api,
   query,
   entities$,
   activeEntity$,
   loading$,
   activeDocumentId$,
   activeReportId$,
};

export default facade;
