import CRUDService from 'state/CRUDService';
import { query } from './query';
import store from './store';

export default class ReportDocumentTemplatesService extends CRUDService {
  constructor() {
    super('reportdocumenttemplates', store, query, [], true, false, undefined, false);
  }

  async uploadTemplate(entity) {
    const formData = new FormData();
    formData.append('name', entity?.name);
    formData.append('desc', entity?.desc);
    formData.append('attachment', entity?.attachment[0]);

    return this.httpClient
      .post(`/${this.version}/${this.entityName}`, formData, {headers:{ 'Content-Type': 'multipart/form-data' }})
      .then((resp) => {
        this.store.add(resp.data);
        return resp.data;
      })
      .catch((error) => {
        this.setError(error);
      });
  }
}
