import { createEntityStore } from '@datorama/akita';
import { ReferencedArea, TaskActionType, TaskCycle, TaskStatus } from 'utils/enum';

export const initialState = {
   statistics: {},
   exports: [],
   type: TaskActionType.TASK,
   searchTerm: undefined,
   filters: {
      status: Array.from(Object.values(TaskStatus)),
      priority: [1, 2, 3, 4, 5],
      area: Array.from(Object.values(ReferencedArea)),
      creator: [],
      assignee: [],
      reviewer: [],
      kpiFieldId: [],
      cycle: Array.from(Object.values(TaskCycle)),
   },
   sortOrder: {
      [TaskActionType.ACTION]: {
         key: 'maturity',
         order: 'desc',
      },
      [TaskActionType.TASK]: {
         key: 'maturity',
         order: 'desc',
      },
      [TaskActionType.SUPPLIER_ACTION]: {
         key: 'maturity',
         order: 'desc',
      },
   },
};

export default createEntityStore(initialState, { resettable: true, name: 'tasks', withActive: 'Active' });
