import { useSetConfig } from './hooks';
import ReportEditorService from './service';

const service = new ReportEditorService();

/**
 * An object that provides a facade for interacting with the report editor service.
 * This facade includes various methods and properties from the `ReportEditorService` and `useSetConfig` hook.
 *
 * @type {Object}
 * @property {string} channelId - The channel ID where the document is stored.
 * @property {Object} initialData - Initial data for the report editor.
 * @property {Function} onReady - Callback function to be called when the editor is ready.
 * @property {Function} refreshDisplayMode - Method to refresh the display mode of the editor.
 * @property {Function} checkPendingActions - Method to check for any pending actions.
 * @property {Function} onError - Callback function to handle errors.
 * @property {Function} onMention - Callback function to handle the mention feature logic.
 * @property {Function} useSetConfig - Hook to manage the configuration of the editor.
 */
const facade = {
   channelId: service.getChannelId(),
   onReady: service.onReady(),
   checkPendingActions: service.checkPendingActions,
   onError: service.onError(),
   onMention: service.onMention,
   useSetConfig,
};

export default facade;
