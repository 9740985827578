import {
   activeEntity$,
   activeFieldGroup$,
   activeMeta$,
   activeRow$,
   entities$,
   entitiesTypeTableColumn$,
   entitiesWithoutValues$,
   loading$,
   query,
} from './query';
import KPIFieldsService from './service';

const api = new KPIFieldsService();

const facade = {
   api,
   query,
   entities$,
   entitiesTypeTableColumn$,
   activeEntity$,
   activeMeta$,
   activeFieldGroup$,
   activeRow$,
   entitiesWithoutValues$,
   loading$,
};

export default facade;
