import type { AxiosInstance } from 'axios';
import axios from 'axios';
import { getUserLocale } from 'get-user-locale';

const axiosClient: AxiosInstance = axios.create({
   baseURL: ``,
   withCredentials: true,
});

axiosClient.defaults.headers.common['Accept-Language'] = getUserLocale().substring(0, 2);

export default axiosClient;
