import { context$, from$, isActive$, periodContextQuery, periodId$, to$ } from 'context/PeriodContext/query';
import { loading$ } from 'context/UserContext/query';
import UsersService from 'state/User/service';

const facade = {
   api: new UsersService(),
   query: periodContextQuery,
   context$,
   loading$,
   from$,
   to$,
   periodId$,
   isActive$,
};

export default facade;
