import { Plugin } from 'ckeditor5';

/**
 * A CKEditor plugin that provides custom tag functionality.
 *
 * This plugin allows the registration of custom tags, the extension of allowed attributes for these tags,
 * and automates the registration of custom tags by listening to conversion events. (The editor can not load the saved document without the custom tags being registered first).
 */
export class CustomTagPlugin extends Plugin {
	/**
	 * Initializes the custom tag plugin.
	 * This method adds commands to the editor for surrounding content with XBRL tags and removing related XBRL tags.
	 * It also automates the registration of XBRL tags by listening to conversion events.
	 */
	init() {
		this.automateCustomTagRegistration();
	}

	/**
	 * Registers multiple custom tags.
	 * @param {string[]} tagNames - An array of custom tag names.
	 */
	registerCustomTags(tagNames) {
		tagNames.forEach((tagName) => this.registerCustomTag(tagName));
		console.log('tags registered!!!!!!', tagNames);
	}

	/**
	 * Registers a new custom tag.
	 * @param {string} tagName - The name of the custom tag.
	 * @param {string[]} allowedAttributes - An array of allowed attributes for the custom tag.
	 */
	registerCustomTag(tagName, allowedAttributes = []) {
		if (!tagName || this.editor.model.schema.getDefinition(tagName)) {
			return;
		}

		// Schema-Definition
		this.editor.model.schema.register(tagName, {
			allowWhere: '$text',
			allowContentOf: '$block',
			allowAttributes: ['mention_uid', ...allowedAttributes],
		});

		this.defineConversionForTag(tagName);
		console.log('tag registered!!!!!!', tagName);
	}

	/**
	 * Extends the allowed attributes for a given custom tag.
	 *
	 * @param {string} tagName - The name of the custom tag.
	 * @param {string[]} allowedAttributes - An array of additional attributes to allow for the custom tag.
	 */
	extendAllowedTagAttributes(tagName, allowedAttributes) {
		const definition = this.editor.model.schema.getDefinition(tagName);
		const currentAllowedAttributes = definition.allowAttributes;

		this.editor.model.schema.extend(tagName, {
			allowAttributes: [...currentAllowedAttributes, ...allowedAttributes],
		});
	}

	/**
	 * Automates the registration of custom tags by listening to conversion events.
	 * This method listens to upcoming conversion events during editor initialization and document changes
	 * to register custom tags as early as possible. (we are using the element tag attribute to detect custom tags)
	 * The editor can not load the saved document without the custom tags being registered first.
	 */
	automateCustomTagRegistration() {
		this.editor.conversion.for('upcast').add((dispatcher) => {
			dispatcher.on('element', (evt, data) => {
				const viewElement = data.viewItem;
				const attributes = Object.fromEntries(viewElement.getAttributes());
				if (attributes?.tag) {
					this.registerCustomTag(attributes?.tag, Object.keys(attributes));
					console.log('Element tags detected in upcast:', JSON.stringify(attributes), viewElement);
				}
			});
		});
	}

	/**
	 * Defines the conversion for a custom tag. https://ckeditor.com/docs/ckeditor5/latest/framework/deep-dive/conversion/intro.html
	 * Upcast is always called first.
	 * @param {string} tagName - The name of the custom tag.
	 */
	defineConversionForTag(tagName) {
		if (!tagName) return;
		// view to model conversion (parsing html)
		this.editor.conversion.for('upcast').elementToElement({
			model: (viewElement, { writer }) => {
				const attributes = Object.fromEntries(viewElement.getAttributes());
				return writer.createElement(tagName, attributes);
			},
			view: tagName,
			converterPriority: 'highest',
		});

		// model to data view (editors output)
		this.editor.conversion.for('dataDowncast').elementToElement({
			model: tagName,
			view: (modelElement, { writer }) => {
				const attributes = modelElement.getAttributes();
				return writer.createContainerElement(tagName, attributes);
			},
			converterPriority: 'highest',
		});

		// model to editing view (editors content in the browser)
		this.editor.conversion.for('editingDowncast').elementToElement({
			model: tagName,
			view: (modelElement, { writer }) => {
				const attributes = modelElement.getAttributes();
				return writer.createContainerElement('span', attributes);
			},
			converterPriority: 'highest',
		});
	}
}
