import { createEntityStore } from '@datorama/akita';

export default createEntityStore(
   {
      searchTerm: undefined,
      sort: {
         key: null,
         order: 'asc',
      },
   },
   { resettable: true, name: 'users', withActive: 'Active' }
);
