import { Box, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { fieldTypeIsNumeric } from 'utils';
import { AggregationLevel } from 'utils/enum';

import { toPng } from 'html-to-image';

export const InfoBox = forwardRef(function InfoBox({ icon, message, ...props }, ref) {
   return (
      <Grid
         container
         direction="column"
         alignItems="center"
         justifyContent="center"
         height="100%"
         paddingBottom="6px"
         flexWrap="nowrap"
         ref={ref}
         {...props}
      >
         <Grid item sx={{ color: 'text.secondary' }}>
            {icon}
         </Grid>
         <Grid item>
            <Typography color="textSecondary" textAlign="center" variant="subtitle1" fontSize="1.125rem">
               {message}
            </Typography>
         </Grid>
      </Grid>
   );
});

InfoBox.propTypes = {
   icon: PropTypes.node.isRequired,
   message: PropTypes.node.isRequired,
};

export const formatOptions = [
   'compactDisplay',
   'currencySign',
   'currencyDisplay',
   'localeMatcher',
   'maximumFractionDigits',
   'maximumSignificantDigits',
   'minimumFractionDigits',
   'minimumSignificantDigits',
   'minimumIntegerDigits',
   'notation',
   'signDisplay',
   'useGrouping',
   'unitDisplay',
   'roundingPriority',
];

export const getDateFormat = (aggregationLevel) => {
   switch (aggregationLevel) {
      case AggregationLevel.YEAR:
         return 'y';
      case AggregationLevel.HALF_YEAR:
         return 'MM/y';
      case AggregationLevel.QUARTER:
         return 'QQQ/y';
      case AggregationLevel.MONTH:
         return 'MM/y';
      case AggregationLevel.YEAR_TO_DATE:
         return "y '(YTD)'";
      default:
         // TODO not sure why we get here sometimes with aggregationLevel === AUTOMATIC
         // console.warn(`unknown aggregation level ${aggregationLevel}`);
         return 'MM/Y';
   }
};

export function determineDefaultStyle(element) {
   if (element?.unit === '%') {
      return 'percent';
   }
   if (element?.unit) {
      return 'unit';
   }
   if (element?.currencyCode ?? element?.currencyId ?? element?.currency) {
      return 'currency';
   }
   if (element?.type === 'PERCENTAGE') {
      return 'percent';
   }
   return 'decimal';
}

export const parseEntity = (entity, kpiFields, kpiContents, kpiSubs, kpis, kpiAreas) => {
   if (entity?.kind === 'footprintcalculationfield' || entity?.kind === 'fpcalculationfield') {
      const footprint = entity?.fpCalculation?.footprintSub?.footprint;
      const footprintSub = entity?.fpCalculation?.footprintSub;
      const footprintCalculation = entity?.fpCalculation;
      if (!footprint || !footprintSub || !footprintCalculation) {
         return { isValid: false, entity };
      }
      return { isValid: true, kind: entity.kind, footprint, footprintSub, footprintCalculation, entity };
   } else if (entity?.kind === 'kpifield' || entity?.kind === 'kpiField' || entity?.kind) {
      if (Array.isArray(kpiFields) && Array.isArray(kpiContents) && Array.isArray(kpiSubs)) {
         const kpiField = kpiFields.find((kpiField_) => kpiField_.id === entity?.id);

         if (!kpiField) {
            return { isValid: false, entity };
         }

         if (!fieldTypeIsNumeric(kpiField?.type)) {
            return { isValid: false, entity };
         }

         const kpiContent = kpiContents.find((kpiContent_) => kpiContent_.slug === kpiField?.kpiContent?.slug);
         const kpiSub = kpiSubs.find((kpiSub_) => kpiSub_.slug === kpiField?.kpiContent?.kpiSub?.slug);
         const kpi = kpis.find((kpi_) => kpi_.slug === kpiField?.kpiContent?.kpiSub?.kpi?.slug);
         const kpiArea = kpiAreas.find((kpiArea_) => kpiArea_.slug === kpiField?.kpiContent?.kpiSub?.kpi?.kpiArea?.slug);

         if (!kpiContent || !kpiSub || !kpi || !kpiArea) {
            return { isValid: false, entity };
         }

         return { isValid: true, kind: entity.kind, kpiField, kpiContent, kpiSub, entity, kpi, kpiArea };
      } else {
         return { isValid: false, entity };
      }
   }
   return { isValid: false, entity };
};

export const getTooltipText = (parsedEntity, intl) => {
   if (parsedEntity?.entity?.kind) {
      if (!parsedEntity?.isValid) {
         return (
            <Box whiteSpace="normal">
               {`${intl.formatMessage({
                  id: 'kpis.kpisubs.kpicontent.kpifields.valueVersions.type',
                  defaultMessage: 'Field Type',
               })}: ${intl.formatMessage({
                  id: `entityKind.${parsedEntity?.entity?.kind}`,
                  defaultMessage: parsedEntity?.entity?.kind,
               })}`}
               <br />
               {`${intl.formatMessage({
                  id: 'dataSource.attributes.slug',
                  defaultMessage: 'Permalink',
               })}: ${parsedEntity?.entity?.slug ?? parsedEntity?.entity?.kpiField?.slug}`}
            </Box>
         );
      }

      switch (parsedEntity.kind) {
         case 'fpcalculationfield':
         case 'footprintcalculationfield':
            if (parsedEntity?.footprint && parsedEntity?.footprintSub && parsedEntity?.footprintCalculation) {
               return (
                  <Box whiteSpace="normal">
                     {`${intl.formatMessage({
                        id: 'entityKind.footprint',
                        defaultMessage: 'Carbon Footprint',
                     })}
                     : ${parsedEntity?.footprint?.name}`}
                     <br />
                     {`${intl.formatMessage({
                        id: 'emissions.calculation.footprintSub',
                        defaultMessage: 'Tab',
                     })}
                     : ${parsedEntity?.footprintSub?.name}`}
                     <br />
                     {`${intl.formatMessage({
                        id: 'entityKind.footprintcalculation',
                        defaultMessage: 'Calculation',
                     })}
                     : ${parsedEntity?.footprintCalculation?.name}`}
                     <br />
                     {`${intl.formatMessage({
                        id: 'drag.fpCalculationField',
                        defaultMessage: 'Field',
                     })}
                     : ${parsedEntity?.entity?.name}`}
                  </Box>
               );
            }
            return '';
         case 'kpifield':
         default:
            if (parsedEntity?.kpiField && parsedEntity?.kpiContent && parsedEntity?.kpiSub && parsedEntity?.kpi && parsedEntity?.kpiArea) {
               return (
                  <Box whiteSpace="normal">
                     {`${intl.formatMessage({
                        id: 'kpis.kpisubs.kpicontent.kpifields.headline',
                        defaultMessage: 'Field',
                     })}: ${parsedEntity?.kpiField.name}`}
                     <br />
                     {`${intl.formatMessage({
                        id: 'kpis.kpisubs.kpicontent.headline',
                        defaultMessage: 'Sub-Section',
                     })}: ${parsedEntity?.kpiContent?.name}`}
                     <br />
                     {`${intl.formatMessage({
                        id: 'kpis.kpisubs.headline',
                        defaultMessage: 'Section',
                     })}: ${parseEntity?.kpiSub?.key ? parseEntity.kpiSub.key.concat(' ').concat(parseEntity?.kpiSub?.name) : parseEntity?.kpiSub?.name}`}
                     <br />
                     {`${intl.formatMessage({
                        id: 'kpis.kpi.add.headline',
                        defaultMessage: 'Sub-Area',
                     })}
        : ${parseEntity?.kpi?.key ? parseEntity?.kpi.key.concat(' ').concat(parseEntity?.kpi?.name) : parseEntity?.kpi?.name}`}
                     <br />
                     {`${intl.formatMessage({
                        id: 'kpis.kpiareas.add.headline',
                        defaultMessage: 'Area',
                     })}
                  : ${parseEntity?.kpiArea?.key ? parseEntity?.kpiArea.key.concat(' ').concat(parseEntity?.kpiArea?.name) : parseEntity?.kpiArea?.name}`}
                  </Box>
               );
            }
            return '';
      }
   }

   return '';
};

const getImageFromChartEl = async (chartEl, options = {}) => {
   if (!chartEl) {
      return null;
   }

   const svg = chartEl;

   return await toPng(svg, {
      quality: 1,
      backgroundColor: 'white',
      pixelRatio: 2,
      ...options,
      style: {
         // insert default CSS here
         ...(options?.style ?? {}),
      },
      // canvasWidth: 400,
      // canvasHeight: 300,
   });
};

export const downloadChartImage = async (chart, chartDataId) => {
   if (!chart) {
      return;
   }
   const link = document.createElement('a');
   link.download = `${chart.name || 'chart'}.jpg`;
   const chartEl = document.querySelector(`[data-download-chart-id="${chartDataId ?? chart?.id}"]`);

   const toggleHiddenElements = (state) => {
      try {
         chartEl.querySelectorAll('[data-download-chart-hide]').forEach((el) => {
            el.style.visibility = state;
         });
      } catch {
         /* do nothing */
      }
   };

   if (!chartEl) {
      console.error('chart element not found');
      return Promise.resolve();
   }

   toggleHiddenElements('hidden');

   try {
      const imgData = await getImageFromChartEl(chartEl);
      link.href = imgData;
      link.click();
   } catch {
      // do nothing
   } finally {
      toggleHiddenElements('visible');
   }
   return Promise.resolve();
};

export const downloadChartImages = async (charts = []) => {
   // use sequential downloads to ease the load
   // and let the browser download fonts for the first request.
   // otherwise, the fonts will be downloaded for each chart
   // (at least for the first batch, next downloads use cache from library)
   for (const chart of charts) {
      // eslint-disable-next-line no-await-in-loop
      await downloadChartImage(chart);
   }
};
