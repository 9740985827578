import { combineQueries, createEntityQuery, Order } from '@datorama/akita';
import { reportingStandards$ } from 'context/OrganisationContext/query';
import { isEqual } from 'lodash-es';
import { distinctUntilChanged, map } from 'rxjs';
import { standardsInReports$ } from 'state/Report/query';
import store from './store';

export const query = createEntityQuery(store);

export const entities$ = query.selectAll({
   sortBy: 'id',
   sortByOrder: Order.ASC,
});
export const activeEntity$ = query.selectActive();

export const entityCount$ = query.selectCount();

export const availableStandards$ = combineQueries([entities$, standardsInReports$]).pipe(
   map(([standards, standardsInReports]) => (standards ? standards.filter(({ key }) => !standardsInReports.includes(key)) : [])),
   distinctUntilChanged(isEqual)
);

export const assignedStandards$ = combineQueries([entities$, reportingStandards$]).pipe(
   distinctUntilChanged(isEqual),
   map(([reportingStandards, orgReportingStandards]) =>
      reportingStandards.filter(({ id }) => orgReportingStandards.some((orgReportingStandard) => id === orgReportingStandard.id))
   )
);

export const loading$ = query.selectLoading();
