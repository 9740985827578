import { combineQueries, createQuery } from '@datorama/akita';
import { activeOrganisationId$ } from 'context/OrganisationContext/query';
import userContextStore from 'context/UserContext/store';
import { distinctUntilChanged, map } from 'rxjs';

export const periodContextQuery = createQuery(userContextStore);
export const context$ = periodContextQuery.select('activePeriod');

export const periodId$ = periodContextQuery.select('activePeriodId');

export const from$ = context$.pipe(
   map((p) => p?.from),
   distinctUntilChanged()
);

export const to$ = context$.pipe(
   map((p) => p?.to),
   distinctUntilChanged()
);

export const isActive$ = combineQueries([context$, activeOrganisationId$]).pipe(
   map(
      ([activePeriod, activeOrganisationId]) =>
         !Array.isArray(activePeriod?.organisations) ||
         activePeriod.organisations.length === 0 ||
         !activePeriod.organisations.some(({ id, period_organisation }) => id === activeOrganisationId && period_organisation?.isActive === false)
   ),
   distinctUntilChanged()
);
