import { PropTypes } from 'prop-types';
import { OrganisationContext } from 'context/index';
import facade from 'context/OrganisationContext/facade';

export const OrganisationContextComponent = function ({ children }) {
  return <OrganisationContext.Provider value={facade.context$}>{children}</OrganisationContext.Provider>;
};

OrganisationContextComponent.propTypes = {
  children: PropTypes.element.isRequired,
};

export default OrganisationContextComponent;
