import { createEntityQuery, Order } from '@datorama/akita';
import store from './store';

export const query = createEntityQuery(store)

export const entities$ = query.selectAll({
  sortBy: 'id',
  sortByOrder: Order.DESC
});
export const activeEntity$ = query.selectActive();
export const loading$ = query.selectLoading();