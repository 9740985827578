import { query } from './query';
import store from './store';

export default class HistoryService {
   constructor() {
      if (!HistoryService.instance) {
         this.store = store;
         this.query = query;
         HistoryService.instance = this;
      }

      // Service shall be instantiated only once, because otherwise the observable will be created for each service instance

      return HistoryService.instance;
   }

   setHistoryReference(storeName, entityId, footprintId = null) {
      return this.store.update({ storeName, entityId, footprintId });
   }
}
