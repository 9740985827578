import { createEntityStore } from '@datorama/akita';
import { GridLogicOperator } from '@mui/x-data-grid-premium';

const store = createEntityStore(
   {
      businessActivities: [],
      isBusinessActivitiesLoading: true,
      filter: {
         items: [],
         logicOperator: GridLogicOperator.And,
         quickFilterValues: [],
         quickFilterExcludeHiddenColumns: true,
         quickFilterLogicOperator: GridLogicOperator.And,
      },
      sortModel: {
         field: 'updatedAt',
         sort: 'desc',
      },
   },
   { resettable: true, name: 'taxonomy/fslis' }
);
export default store;
