import { PropTypes } from 'prop-types';
import { PeriodContext } from 'context/index';
import facade from 'context/PeriodContext/facade';

export const PeriodContextComponent = function ({ children }) {
  return <PeriodContext.Provider value={facade.context$}>{children}</PeriodContext.Provider>;
};

PeriodContextComponent.propTypes = {
  children: PropTypes.element.isRequired,
};

export default PeriodContextComponent;
