import { createQuery } from '@datorama/akita';
import { v4 as uuidv4 } from 'uuid';

import applicationContextStore from 'context/ApplicationContext/store';

export default class ApplicationService {
   constructor() {
      if (!ApplicationService.instance) {
         this.store = applicationContextStore;
         this.query = createQuery(this.store);

         ApplicationService.instance = this;
      }

      // Service shall be instantiated only once, because otherwise the observable will be created for each service instance
       
      return ApplicationService.instance;
   }

   setProperty(propertyName, value) {
      this.store.update({ [propertyName]: value });
   }

   getProperty(propertyName) {
      return this.query.getValue()?.[propertyName];
   }

   setLoading(loading) {
      this.store.setLoading(loading);
   }

   setError(error) {
      this.store.setError({
         code: error?.response?.status ?? error?.response?.data?.code,
         variant: 'error',
         message: `${error?.response?.data?.message ?? error.message ?? ''} ${error?.response?.data?.error ?? ''}`,
         key: `${this.store?.storeName}#${uuidv4()}`,
         store: this.store?.storeName,
      });
      this.store.setLoading(false);
   }
}
