import { loading$, query, value$ } from 'state/Design/query';
import DesignService from 'state/Design/service';

const api = new DesignService();
api.getDesign();

const facade = {
  api,
  query,
  value$,
  loading$,
};

export default facade;
